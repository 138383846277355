export function toSlug(value) {
  return value && value.toLowerCase
    ? value
        .toLowerCase()
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ß/g, 'ss')
        .replace(/ /g, '-')
        .replace(/\./g, '')
        .replace(/,/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .replace(/\'/g, '')
    : value
  //return value.toLowerCase().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').split(' ').join('-');
}

export function trimRight(string, chars) {
  String.prototype.trimRight = function (charlist) {
    if (charlist === undefined) charlist = 's'

    return this.replace(new RegExp('[' + charlist + ']+$'), '')
  }

  return string.trimRight(chars)
}

export function trim(string) {
  return string.trim()
}

export function toLocaleNumber(number) {
  return number.toLocaleString()
}

const kebabCaseRegex = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g

export function kebabCase(string) {
  // TODO: confirm with Eric, doe not work for Chinese
  try {
    return `${string}`
      .match(kebabCaseRegex)
      .map((x) => x.toLowerCase())
      .join('-')
  } catch (e) {
    console.warn("Can't kebabCase", string)
    return string
  }
}

export const upperCase = (s) => {
  return s.toUpperCase()
}

export const dedupeObjectArray = (array, propToDedupe) => {
  const propSet = new Set()
  return array.filter((obj) => !propSet.has(obj[propToDedupe]) && propSet.add(obj[propToDedupe]))
}
