export function useArabicLanguage() {
  const { locale } = useI18n()

  const isArabic = computed(() => locale.value === 'ar')

  const applyArabic = (classToApply: string) => {
    if (!isArabic.value) {
      return
    }

    return classToApply
  }

  return {
    isArabic,
    applyArabic,
  }
}
