const mockedCustomerSettings = {
  Shop: {
    EngravingsInBag: 0,
    DiamondsInBag: [],
  },
  Express: {
    ItemsInBag: [],
    EngravingsInBag: 0,
    DiamondsInBag: [],
  },
  CollectionPlaceId: 1,
  DiscountCode: null,
}

// TODO: implement real logic
export const getCustomerSettings = () => {
  return mockedCustomerSettings
}
