export const stripBaseUrl = (path) => {
  return path.replace(/^https?:\/\/[^#?\/]+/, '')
}

export const getCleanUrlSearchParams = (query) => {
  const searchParams = new URLSearchParams(query)
  Array.from(searchParams.entries()).forEach(([key, value]) => {
    if (!value || value === 'undefined') {
      searchParams.delete(key)
    }
  })

  return searchParams
}

export const normalizeUrlInLanguages = (UrlInLanguages) =>
  UrlInLanguages?.map((x) => {
    const newUrl = new URL(x.Url)

    return {
      ...x,
      Url: window?.location.origin + newUrl.pathname + window?.location.search,
    }
  })
