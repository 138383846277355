import { useNewSettingsStore } from '~/store/newSettingsStore'
import { getCategoryIdByValue } from '~/utils/shop/categories'
import { getSubCategoryIdByValue } from '~/utils/shop/subCategories'
import { getItems } from '~/utils/shop/items'
import { getI18nLanguageId } from '~/utils/languages'
import { getCustomerSettings } from '~/utils/customerSettings'

export const getModifiedOptions = (options: Record<string, unknown>): Record<string, never> => {
  const { currentCountry, currentCurrency, currentLanguage, userPreferences } = useNewSettingsStore()

  const country = currentCountry.Id || userPreferences?.CountryId
  const currency = currentCurrency.Id || userPreferences?.CurrencyId
  const language = currentLanguage.Id || getI18nLanguageId()

  let storageFilters = {}
  try {
    let _77dShopQuery = ''
    if (sessionStorage['77dShopQuery'].length <= 2) {
      sessionStorage.setItem('77dShopQuery', JSON.stringify({ 'discount-code': localStorage.getItem('addDiscount') }))
      _77dShopQuery = JSON.stringify({ 'discount-code': localStorage.getItem('addDiscount') })
    } else {
      _77dShopQuery = sessionStorage['77dShopQuery']
    }
    if (_77dShopQuery) {
      storageFilters = JSON.parse(_77dShopQuery)
    }
  } catch (e) {}

  return options?.method === 'POST'
    ? {
        ...options,
        body: {
          ...options.body,
          country,
          currency,
          language,
          'discount-code':
            options['discount-code'] || storageFilters['discount-code'] || getCustomerSettings().DiscountCode,
          diamondsInBag: getCustomerSettings().Shop.DiamondsInBag || [],
          expressItemsInBag: getCustomerSettings().Express.ItemsInBag || [],
          engravingsInBag: getCustomerSettings().EngravingsInBag || 0,
          url: window?.location.href,
        },
      }
    : options
}

export const returnDiamondsCode = (diamond: Array<string | number>): string[] | null => {
  if (!diamond) return null
  const diamondString = diamond.toString()
  return toArray(diamondString.split(','))
}

export const toArray = <T>(object: T | T[] | null | undefined): T[] => {
  if (!object) return []
  if (Array.isArray(object)) return object
  if (typeof object === 'string') {
    const array = object.includes('/') ? object.split('/') : object.split(',').map((item) => item.trim())
    return array as unknown as T[]
  }
  return [object] as T[]
}

export const getItemParams = ({
  category,
  subcategory,
  item,
  query,
  forceBandSettings = false,
}: {
  category: string | number
  subcategory: string | number
  item: string
  query: Record<string, any>
  forceBandSettings: boolean
}) => {
  const _item = isNaN(+item) ? _getItem({ category, subcategory, label: item }) : { id: item, subcategory }
  const { currentCountry, currentCurrency, currentLanguage } = useNewSettingsStore()
  let { sizeName, attributeId, size } = query
  const {
    sizeNameAttributeId,
    metal,
    stoneType,
    diamondType,
    shape,
    shapes,
    selectedDiamonds,
    diamond,
    component,
    variation,
    componentSet,
    depth,
    width,
    ringSize,
    country,
    gemType,
    stockNumber,
    quickShipping,
    engraving,
    engraving_font,
  } = query
  if (query.sizeNameAttributeId) {
    const [splittedSizeName, splittedAttributeId] = sizeNameAttributeId.split(',')
    sizeName = splittedSizeName
    attributeId = splittedAttributeId
    size = size || splittedAttributeId
  }

  return {
    languageId: currentLanguage.Id,
    countryId: currentCountry.Id,
    currencyId: currentCurrency.Id,
    itemId: _item.id,
    subCategoryId: _item.subcategory,
    metalId: metal || 2,
    stoneType: stoneType || null,
    diamondType: diamondType || null,
    shapeId: shape || (shapes && shapes[0]),
    selectedDiamonds:
      selectedDiamonds && Array.isArray(selectedDiamonds) && selectedDiamonds.length > 0
        ? toArray(selectedDiamonds.map((d) => d.Code.trim()).join())
        : returnDiamondsCode(diamond),
    compsAndVariations:
      component || variation
        ? {
            componentSetId: componentSet,
            componentId: component,
            variationId: variation,
          }
        : null,
    bandSettings:
      forceBandSettings || depth || width || size || sizeName || ringSize
        ? {
            depthId: depth,
            widthId: width,
            ringSizeAttributeId: attributeId,
            country: country || 826,
            ringSizeIndex: size,
            RingSizeName: ringSize || sizeName,
          }
        : null,
    gemType: gemType,
    stockNumber: stockNumber,
    quickShipping: category == 4 || category == 1 ? !!quickShipping : false,
    engraving: engraving
      ? {
          description: engraving,
          fontOptionId: engraving_font,
        }
      : null,
  }
}

export const _getItem = ({
  category,
  subcategory,
  id,
  label,
}: {
  category: string | number
  subcategory: string | number
  id?: string | number
  label: string
}) => {
  if (typeof category === 'string' && isNaN(category)) {
    category = getCategoryIdByValue(category)
  }
  if (typeof subcategory === 'string' && isNaN(subcategory)) {
    subcategory = getSubCategoryIdByValue(subcategory)
  }

  let items = getItems().filter((item) => (!id || item.id === id) && (!label || item.label == label))

  if (items.length > 1) {
    items = items.filter((item) => item.subcategory == subcategory)
  }

  return items[0]
}
