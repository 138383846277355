import { findBy, join, removeDuplicatedComplex } from './general.js'
import { useNewSettingsStore } from '~/store/newSettingsStore'

// Import the utility functions from your project

let items = []
const getItemsSingleton = () => {
  const { shopSettings } = useNewSettingsStore()

  if (items.length > 0) {
    return items
  }

  items = removeDuplicatedComplex(shopSettings?.Routing.Items || [], ['Id', 'SubCategoryId', 'FriendlyName']).map(
    (item) => ({
      id: item.Id,
      //category: item.,
      subcategory: item.SubCategoryId,
      label: item.FriendlyName,
    }),
  )

  return items
}

export function getItems() {
  return getItemsSingleton()
}

export function getItemById(id) {
  return findBy(getItems(), 'id', id)
}

export function getItemByValue(value) {
  return findBy(getItems(), 'value', value)
}

export function getItemByLabel(label) {
  return findBy(getItems(), 'label', label)
}

export function joinItems(separator, field = 'label') {
  return join(getItems(), field, separator)
}
