import { createApp } from 'vue'
import Alert from '~/components/ui/Alert/Alert.vue'

export function removeElement(el: HTMLElement): void {
  if (typeof el.remove !== 'undefined') {
    el.remove()
  } else if (el.parentNode) {
    el.parentNode.removeChild(el)
  }
}

export function spawn(propsData: Record<string, unknown>): void {
  const container = document.getElementById('alert-container')

  if (!container) return

  const app = createApp(Alert, propsData)

  const alertElement = document.createElement('div')
  container.appendChild(alertElement)
  app.mount(alertElement)
}

export function show({
  variant = 'success',
  isSmall = false,
  dismissible = true,
  showIcon = true,
  message,
  autoDismiss = false,
}: {
  variant?: string
  isSmall?: boolean
  dismissible?: boolean
  showIcon?: boolean
  message: string
  autoDismiss?: boolean
}): void {
  const container = document.getElementById('alert-container')
  if (!container) return

  const oldAlert = container.firstChild as HTMLElement
  if (oldAlert) removeElement(oldAlert)

  spawn({
    variant,
    isSmall,
    dismissible,
    showIcon,
    message,
  })

  if (autoDismiss) {
    setTimeout(() => {
      const alert = container.firstChild as HTMLElement
      if (alert) removeElement(alert)
    }, 5000)
  }
}
