import { ga4_virtualRingStylerInteractions } from './general.js'

export const ga_options = {}

export function ga_event_quick_init(category, action) {
  ga_event({
    category: `${category}`,
    action: `${action}`,
    label: '',
    value: 0,
    nonInteraction: 'true',
    pageType: 'Shop',
  })
}

export function ga_event_popups(action, label) {
  ga_event({
    category: '',
    action: action,
    label: label,
    value: 0,
    nonInteraction: 'true',
    pageType: 'Shop',
  })
}

export function ga_event(event) {
  if (!event.label) {
    event.label = ''
  }

  window.dataLayer = window.dataLayer || []
  dataLayerPush({
    event: 'ga_event',
    ga_event: event,
    eventCallback: function (containerId) {
      if (containerId === 'GTM-P86RX2') {
        // <<callback code>>
      }
    },
  })

  if (event && event.category === 'virtual ring styler interaction') {
    ga4_virtualRingStylerInteractions(event.action, event.value)
  }
}

export let ga_event_lastStep = ''

export function ga_event_flow(step) {
  if (step != ga_event_lastStep) {
    ga_event({
      category: `product experience | ${ga_options.productCategory}`,
      action: `flow | start with | ${ga_options.configurable}`,
      label: `step completed | ${step}`,
      value: 0,
      nonInteraction: 'true',
    })
  }

  ga_event_lastStep = step
}

function dataLayerPush(data) {
  try {
    if (window.DataLayer) {
      window.DataLayer.push(data)
    }
  } catch (error) {
    console.warn(`error calling datalayer.dataLayerPush`, data)
  }
}
