import { _months, _weekdays } from '@/constants/utilConstants.ts'

export function getFormattedLongDate(leadTimeInDays, capitalize, translate) {
  const date = leadTimeInDays ? new Date().getTime() + leadTimeInDays * 24 * 60 * 60 * 1000 : new Date().getTime()
  const day = new Date(date).getDate()
  const year = new Date(date).getFullYear()
  let weekday = translate(_weekdays[new Date(date).getDay()]).toLowerCase()
  let month = translate(_months[new Date(date).getMonth()]).toLowerCase()

  if (capitalize) {
    weekday = weekday.charAt(0).toUpperCase() + weekday.slice(1)
    month = month.charAt(0).toUpperCase() + month.slice(1)
  }
  const formattedDate = weekday + ', ' + day + ' ' + month + ' ' + year
  return formattedDate
}

export function getFullDate(date, format) {
  const { $i18n } = useNuxtApp()
  const year = new Date(date).getFullYear().toString()
  const monthIndex = new Date(date).getMonth()
  const weekdayIndex = new Date(date).getDay()
  const weekday = $i18n.t(_weekdays[weekdayIndex])
  const fullMonth = $i18n.t(_months[monthIndex])
  let month = monthIndex + 1
  month = month <= 9 ? '0' + month.toString() : month.toString()
  let day = new Date(date).getDate()
  day = day <= 9 ? '0' + day.toString() : day.toString()

  switch (format) {
    case 'NO_YEAR':
      return (
        weekday.charAt(0).toUpperCase() +
        weekday.slice(1) +
        ', ' +
        day +
        ' ' +
        fullMonth.charAt(0).toUpperCase() +
        fullMonth.slice(1)
      )
    case 'DAY-MONTH':
      return day + ' ' + fullMonth.charAt(0).toUpperCase() + fullMonth.slice(1)
    case 'DAY-MONTH-YEAR':
      return day + ' ' + fullMonth.charAt(0).toUpperCase() + fullMonth.slice(1) + ' ' + year
    case 'YYYY/MM/DD':
      return year + '/' + month + '/' + day
    case 'DD/MM/YYYY':
      return day + '/' + month + '/' + year
    case 'LONG_WEEKDAY':
      return (
        weekday.charAt(0).toUpperCase() +
        weekday.slice(1) +
        ', ' +
        day +
        ' ' +
        fullMonth.charAt(0).toUpperCase() +
        fullMonth.slice(1) +
        ' ' +
        year
      )
    default:
      return day + '/' + month + '/' + year.slice(-2)
  }
}

export const addHoursToDate = (date, hours) => {
  return new Date(date.getTime() + hours * 60 * 60 * 1000)
}
