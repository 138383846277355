<template>
  <div class="sticky right-0 left-full pointer-events-none z-20 w-fit" :style="{ bottom: `${offset + 10}px` }">
    <div class="mr-4 left-full right-0 pointer-events-auto">
      <div class="relative flex flex-col-reverse gap-4 sm:flex-row sm:items-end sm:justify-end sm:gap-2">
        <LayoutFooterGiveaway v-if="showGiveaway" />
        <div class="flex flex-col items-end absolute right-0 bottom-[calc(100%+0.5rem)]">
          <LayoutBackToTop />
          <LayoutChat />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useLayoutStore } from '~/store/layoutStore'

const { showCookieBar, showExpressShoppingBar, showItemFinishAddToBag, showGiveaway } = storeToRefs(useLayoutStore())

const offset = computed(() => {
  let offset = 0
  if (showExpressShoppingBar.value) offset += 95
  if (showCookieBar.value) offset += 40
  if (showItemFinishAddToBag.value) offset += 50
  return offset
})
</script>

<style lang="scss" scoped>
.with-cookie-bar {
}
</style>
