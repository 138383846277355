export const COMPANIES_CODE = {
  1: {
    code: '77D',
    countryCode: 'UK',
  },
  2: {
    code: '77D-GMBH',
    countryCode: 'GmbH',
  },
  3: {
    code: 'TOVA',
    countryCode: 'TOVA',
  },
  4: {
    code: '77D-SWISS-GMBH',
    countryCode: 'SwissGmbH',
  },
} as const

export type CompanyData = {
  name: string
  address: string
  extra?: string
  phone?: string
  companyNumber?: string
}

type CompanyDataList = { [key in AddressOptions]: CompanyData }
export type CompanyCodeOptions = '77D' | '77D-GMBH' | '77D-SWISS-GMBH'

export const COMPANY_DATA: CompanyDataList = {
  '77D-SWISS-GMBH': {
    name: '77 Diamonds Swiss GmbH',
    address: 'Talacker 41. 8001 Zürich. Schweiz',
    extra: 'UID: CHE-483.881.027',
  },
  '77D-GMBH': {
    name: '77 Diamonds GmbH',
    address: 'Schumannstraße 27. 60325 Frankfurt. Deutschland',
    extra: 'Handelsregisternummer: HR B 115026 (Amtsgericht Frankfurt am Main)',
    phone: '+49 (0) 69 9754 6177,',
  },
  default: {
    name: '77 Diamonds Limited - Registered in England and Wales',
    address: '2nd Floor, 3 Hanover Square, London, W1S 1HD',
    phone: '+44 203 540 1477',
    companyNumber: '05142579',
  },
}
