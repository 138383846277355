<template>
  <div v-if="currentEvent?.IsActive" class="topbar" :frames="hasCountdown ? 3 : 2">
    <NuxtLink :href="saleHref" class="topbar-frame -sm">
      <template v-for="(phaseText, index) in salePhaseTexts">
        <span v-if="index"> — </span>
        {{ parsePhaseText(phaseText) }}
      </template>
    </NuxtLink>
    <NuxtLink v-if="hasCountdown" :to="saleHref" class="topbar-frame -sm md:ml-5">
      <section class="_77Clock usp-bar-countdown" :class="{ 'ends-today': formattedTime.days === '00' }">
        <div class="countdown">
          <div class="timeHolder days">
            <div>
              <span>{{ formattedTime.days }}</span>
            </div>
            <div class="label">{{ $t('days', 'Days') }}</div>
            <div class="label alternative">{{ $t('days-short', 'D') }}</div>
          </div>
          <div class="timeHolder hours">
            <div>
              <span>{{ formattedTime.hours }}</span>
            </div>
            <div class="label">{{ $t('hours', 'Hours') }}</div>
            <div class="label alternative">{{ $t('hours-short', 'H') }}</div>
          </div>
          <div class="timeHolder mins">
            <div>
              <span>{{ formattedTime.minutes }}</span>
            </div>
            <div class="label">{{ $t('minutes-abbr', 'Mins') }}</div>
            <div class="label alternative">{{ $t('minutes-short', 'M') }}</div>
          </div>
          <div class="timeHolder secs">
            <div>
              <span>{{ formattedTime.seconds }}</span>
            </div>
            <div class="label">{{ $t('seconds-abbr', 'Secs') }}</div>
            <div class="label alternative">{{ $t('seconds-short', 'S') }}</div>
          </div>
        </div>
      </section>
    </NuxtLink>
    <NuxtLink :to="saleHref" class="topbar-frame -sm md:ml-5">
      <span class="topbar-link">{{ parsePhaseText(currentEvent?.CtaText) }}</span>
    </NuxtLink>
  </div>

  <div v-else class="topbar" frames="4">
    <NuxtLink id="USPChatLink" href="javascript:void(0)" class="topbar-frame -sm">
      {{ $t('spring-sale-2019-24-7-customer-service', '24/7 Customer Service') }}
    </NuxtLink>

    <template v-if="isUK()">
      <NuxtLinkLocale to="/77diamonds-delivery.html" class="topbar-frame -sm md:ml-6">
        {{ t('free-and-insured-global-shipping', 'Free and insured global shipping') }}
      </NuxtLinkLocale>
    </template>
    <template v-else>
      <NuxtLinkLocale to="/finance-information" class="topbar-frame -sm md:ml-6">
        {{ t('interest-free-finance', 'Interest Free Finance') }}
      </NuxtLinkLocale>
    </template>

    <NuxtLink :to="localePath('/our-promises') + '#moneyBackGuarantee'" class="topbar-frame -sm md:ml-6">
      {{ $t('money-back-guarantee-30', '30 Day Money Back Guarantee') }}
    </NuxtLink>
    <NuxtLink :to="localePath('/our-promises') + '#lifetimeGuarantee'" class="topbar-frame -sm md:ml-6">
      {{ $t('spring-sale-2019-lifetime-warranty', 'Lifetime Warranty') }}
    </NuxtLink>
  </div>
</template>

<script setup>
// TODO: use API or campaign service to get the sale data
const props = defineProps({
  salesName: String,
  salesEndDate: String,
  salesPageCtaText: String,
  salePhaseText: String,
  saleHref: String,
})

const SALE_PHASE_THRESHOLD = 24
const COUNTDOWN_THRESHOLD = 96

const MINUTE_IN_SECONDS = 60
const HOUR_IN_SECONDS = MINUTE_IN_SECONDS * 60
const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24

const { t } = useI18n()
const localePath = useLocalePath()

const { currentEvent } = useEvents()

const salePhaseTexts = ref(currentEvent?.PhaseTexts)
const salesEndDate = ref(currentEvent?.End) // Set your sale end date
const saleHref = ref(currentEvent?.Type == 'sitewide' ? 'sitewide' : 'signup') // Set your sale end date

// Compute the time until the sale ends
const endDateTimestamp = computed(() => new Date(salesEndDate.value).getTime())
const timeUntilEnd = ref(Math.max(endDateTimestamp.value - Date.now(), 0))

// Determine if there's a countdown based on the sales end date being in the future
const hasCountdown = ref(endDateTimestamp.value < addHoursToDate(new Date(), COUNTDOWN_THRESHOLD))

// Compute the formatted time until the sale ends
const formattedTime = computed(() => {
  const secondsUntilEnd = timeUntilEnd.value / 1000

  const days = Math.floor(secondsUntilEnd / DAY_IN_SECONDS)
  const hours = Math.floor((secondsUntilEnd % DAY_IN_SECONDS) / HOUR_IN_SECONDS)
  const minutes = Math.floor((secondsUntilEnd % HOUR_IN_SECONDS) / MINUTE_IN_SECONDS)
  const seconds = Math.floor(secondsUntilEnd % MINUTE_IN_SECONDS)

  return {
    days: String(days).padStart(2, '0'),
    hours: String(hours).padStart(2, '0'),
    minutes: String(minutes).padStart(2, '0'),
    seconds: String(seconds).padStart(2, '0'),
  }
})

const endWeekDay = computed(() => {
  const date = new Date(salesEndDate.value)
  return new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date)
})

// Compute the sale phase text based on the sale end date and current time
const computeSalePhaseText = () => {
  // Update 'timeUntilEnd' every second
  if (hasCountdown.value) {
    const interval = setInterval(() => {
      if (timeUntilEnd.value <= 0) {
        clearInterval(interval)
      }

      timeUntilEnd.value = Math.max(endDateTimestamp.value - Date.now(), 0)
    }, 1000)

    if (new Date(salesEndDate.value) < addHoursToDate(new Date(), SALE_PHASE_THRESHOLD)) {
      salePhaseTexts.value = [
        {
          Shortname: 'sale-ends-tonight',
          Text: '{saleName} Ends Tonight',
        },
      ]
    } else {
      salePhaseTexts.value = [
        {
          Shortname: 'sale-ends-weekday',
          Text: '{saleName} Ends {weekday}',
        },
      ]
    }
  }
}

const parsePhaseText = (phaseText) => {
  const saleDetails = {
    discount: currentEvent.Discount,
    saleName: currentEvent.Name,
    weekday: endWeekDay.value,
  }

  return t(phaseText.Shortname, saleDetails, phaseText.Text)
}

onMounted(() => {
  computeSalePhaseText()
})
</script>

<style scoped>
.topbar {
  position: relative;
  overflow: hidden;
  background-color: #ffc13b;
  height: 36px;
  text-align: center;
}

a {
  @apply no-underline;
}

.topbar .topbar-frame {
  line-height: 36px;
  white-space: nowrap;
  color: #0d0900;
  font-family: 'Plain Light', sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  text-shadow: 0 0;
  text-transform: capitalize;
}

@media screen and (min-width: 768px) {
  .topbar .topbar-frame {
    font-size: 14px;
  }
}

.topbar .topbar-link {
  font-size: 12px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  border-bottom: 1px solid;
  vertical-align: bottom;
}

.topbar.isblackfriday {
  background-color: #0d0900;
}

.topbar.isblackfriday .topbar-frame {
  color: white;
}

@media screen and (max-width: 767.8px) {
  .topbar .topbar-frame.-sm {
    position: absolute;
    width: 100%;
    height: 36px;
    left: 0;
    top: 100%;
  }
}

.topbar .topbar-frame:not(.-sm) {
  position: absolute;
  width: 100%;
  height: 36px;
  left: 0;
  top: 100%;
}

.topbar .topbar-frame {
  animation-iteration-count: infinite;
}

.topbar .topbar-frame:nth-child(1) {
  animation-delay: -0.5s;
}

.topbar .topbar-frame:nth-child(2) {
  animation-delay: -8.5s;
}

.topbar .topbar-frame:nth-child(3) {
  animation-delay: -16.5s;
}

.topbar .topbar-frame:nth-child(4) {
  animation-delay: -24.5s;
}

.topbar[frames='2'] .topbar-frame {
  animation-name: frames_2;
  animation-duration: 16s;
}

.topbar[frames='3'] .topbar-frame {
  animation-name: frames_3;
  animation-duration: 24s;
}

.topbar[frames='4'] .topbar-frame {
  animation-name: frames_4;
  animation-duration: 32s;
}

@keyframes frames_2 {
  0% {
    top: 100%;
  }
  1% {
    top: 0;
  }
  50% {
    top: 0;
  }
  51% {
    top: -100%;
  }
  100% {
    top: -100%;
  }
}

@keyframes frames_3 {
  0% {
    top: 100%;
  }
  1% {
    top: 0;
  }
  33% {
    top: 0;
  }
  34% {
    top: -100%;
  }
  100% {
    top: -100%;
  }
}

@keyframes frames_4 {
  0% {
    top: 100%;
  }
  1% {
    top: 0;
  }
  25% {
    top: 0;
  }
  26% {
    top: -100%;
  }
  100% {
    top: -100%;
  }
}

._77Clock {
  display: inline-block;
  line-height: 1;
  vertical-align: 2px;

  .timeHolder {
    display: inline-block;
    margin: 0 2px;
    vertical-align: middle;

    > div {
      display: inline-block;
    }

    span {
      font-size: 20px;
      width: 24px;
      display: inline-block;
      margin-top: 1px;
    }
  }

  .secs {
    display: none;
  }

  &.ends-today {
    .secs {
      display: inline-block;
    }

    .days {
      display: none;
    }
  }

  .countdown {
    @media screen and (min-width: 375px) {
      margin: 0 3px;

      .timeHolder {
        margin: 0 3px;
      }
    }

    @media screen and (min-width: 768px) {
      /*margin: 0 10px;*/
      .timeHolder {
        margin: 0 10px;
      }
    }

    .label {
      vertical-align: super;
      font-size: 12px;
      width: 10px;

      @media screen and (min-width: 375px) {
        margin: 0 4px;
      }

      &:not(.alternative) {
        display: none;
      }
    }
  }
}
</style>
