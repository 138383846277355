import { useNewSettingsStore } from '~/store/newSettingsStore'

interface Breadcrumb {
  Url?: string
  path?: string
  [key: string]: any
}

interface BreadcrumbItem {
  PageId: number
  Breadcrumbs?: Breadcrumb[]
  [key: string]: any
}

export const useBreadcrumbsStore = defineStore('breadcrumbs', () => {
  const breadcrumbs: Ref<Breadcrumb[]> = ref([])
  const { currentCountry, currentLanguage } = storeToRefs(useNewSettingsStore())

  const fetchBreadcrumbs = async () => {
    breadcrumbs.value = await $fetch('/api/v1/seo/breadcrumbs', {
      method: 'GET',
      query: {
        languageId: currentLanguage.value?.Id,
        countryId: currentCountry.value?.Id,
      },
    })
  }

  const breadcrumbsWithCurrentOrigin: Ref<BreadcrumbItem[]> = computed(() => {
    return breadcrumbs.value.map((b) => {
      let newBreadcrumbs: Breadcrumb[] = []
      if (b.Breadcrumbs?.length > 0) {
        newBreadcrumbs = b.Breadcrumbs.map((breadcrumb) => {
          if (!breadcrumb.Url) return breadcrumb

          const path = breadcrumb.Url.replace('https://www.77diamonds.com', '')
          const newUrl = `${window.origin}${path}`
          return { ...breadcrumb, Url: newUrl, path }
        })
      }
      return { ...b, Breadcrumbs: newBreadcrumbs }
    })
  })

  const getBreadcrumbs = (pageId: string): BreadcrumbItem | undefined => {
    const pageIdNumber = Number(pageId)
    return breadcrumbsWithCurrentOrigin.value.find((p) => p.PageId === pageIdNumber)
  }

  return {
    fetchBreadcrumbs,
    breadcrumbsWithCurrentOrigin,
    getBreadcrumbs,
    breadcrumbs,
  }
})
