<template>
  <div
    id="newsLetterSubscriptionBottomRightFooter"
    class="relative md:z-20 [transition:all_0.4s] ml-auto"
  >
    <transition name="giveaway-fade" mode="out-in">
      <div
        v-if="showGiveawayButton"
        class="giveaway-button-container z-20"
        :class="`${!showGiveawayButton ? 'isHidden' : ''} ${showCookieBar ? '' : ''}`"
      >
        <button
          class="rounded-full bg-black px-8 text-white flex items-center tracking-normal body-14-aa-cap h-[44px] toggleGiveaway"
          @click="handleGiveawayClick"
        >
          {{ $t('win-77diamonds-jewel', 'Win a 77 Diamonds jewel') }}
          <i class="ml-3 -mr-3 rotate-180 expand-button icon77 icon77-carat-down_white"></i>
        </button>
      </div>
    </transition>

    <div class="expandedArea absolute md:max-h-[85vh] md:overflow-auto md:w-[400px] md:bottom-[58px] md:right-[-60px]">
      <transition name="expand">
        <giveaway-content v-if="showModal" @close-giveaway="onGiveawayClose" />
      </transition>
    </div>
  </div>
</template>

<script setup>
import { useLayoutStore } from '~/store/layoutStore'
import GiveawayContent from '~/components/layout/footer/GiveawayContent.vue'
const { scrollTo } = useScrollTo()

const { showCookieBar } = useLayout()
const { isMobile } = useMQ()
const { showGiveawayButton, showGiveaway } = storeToRefs(useLayoutStore())

const handleGiveawayClick = () => {
  if (isMobile.value) {
    scrollTo('giveawayContent')
    return
  }
  showModal.value = !showModal.value
}
const showModal = ref(false)

const onGiveawayClose = () => {
  showModal.value = false
  showGiveawayButton.value = false
  setTimeout(() => {
    showGiveaway.value = false
  }, 400)
}
</script>

<style lang="scss">
#newsLetterSubscriptionBottomRightFooter {
  .isHidden {
    opacity: 0;
    visibility: hidden;
  }
  .giveaway-consent {
    a {
      text-decoration: underline !important;
    }
  }
}

.expandedArea {
  &.isVisible {
    box-shadow: 0 5px 11px 3px rgba(0, 0, 0, 0.35);
  }
}

.expand-enter-active,
.expand-leave-active {
  transition:
    max-height 0.4s ease,
    opacity 0.4s ease;
}

.expand-enter-from,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}

.expand-enter-to,
.expand-leave-from {
  max-height: 500px; /* Adjust based on content */
  opacity: 1;
}

// button transition

.giveaway-button-container {
  transition: opacity 0.4s ease;
}
.giveaway-fade-enter-active,
.giveaway-fade-leave-active {
  transition: opacity 0.4s ease;
}

.giveaway-fade-enter-from,
.giveaway-fade-leave-to {
  opacity: 0;
}

.giveaway-fade-enter-to,
.giveaway-fade-leave-from {
  opacity: 1;
}
</style>
