<template>
  <div class="localizedLogo basis-1/2 text-center" data-cy="logo">
    <NuxtLinkLocale to="/" class="">
      <Image77
        class="localized-logo"
        :path="'/images/logos/' + (isWhite ? 'white/' : '') + logoName"
        :alt="logoAlt"
        imageClass="h-[35px]"
      />
    </NuxtLinkLocale>
  </div>
</template>

<script setup lang="ts">
import Image77 from '@/components/ui/Image77'

const props = defineProps({
  isWhite: {
    type: Boolean,
    default: false,
  },
})

const logoName = computed(() => {
  if (isUK()) return '77DiamondsLogo-uk.svg?v=3'
  else if (isGermany()) return '77DiamondsLogo-de.svg?v=3'
  else if (isSweden()) return '77DiamondsLogo-stockholm.svg?v=3'
  else if (isSwitzerland()) return 'Logo-ZRH-Geneva.svg?v=3'
  else if (isUAE()) return '77DiamondsLogo-ae.svg?v=3'
  else if (isEuropeanCountry()) return `${props.isWhite ? '77DiamondsLogo.svg' : '77DiamondsLogo-03.svg?v=3'}`
  else return '77DiamondsLogo-04.svg?v=3'
})

const logoAlt = computed(() => {
  if (isUK()) return '77 Diamonds, London | Manchester'
  if (isGermany()) return '77 Diamonds, Berlin | Frankfurt | Munich'
  if (isSweden()) return '77 Diamonds, Stockholm'
  if (isSwitzerland()) return '77 Diamonds, Geneva | Zurich'
  if (isEuropeanCountry()) return '77 Diamonds, Zurich | Berlin | Stockholm'
  if (isUAE()) return '77 Diamonds, Dubai'
  return '77 Diamonds, London | Berlin | Zurich'
})
</script>

<style scoped lang="scss"></style>
