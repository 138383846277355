export const useScrollTo = () => {
  // navigation height + offset
  const NAVIGATION_HEIGHT = 80

  const scrollTo = (elementId: string = '') => {
    if (!elementId) {
      return console.error('Scroll target element id is not defined')
    }
    const target = document.getElementById(elementId)
    const { top } = useElementBounding(target)
    const { y } = useWindowScroll({ behavior: 'smooth' })

    y.value = y.value + top.value - NAVIGATION_HEIGHT
  }

  return {
    scrollTo,
  }
}
