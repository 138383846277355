<template>
  <transition
    enter-active-class="transition duration-300 ease-out transform"
    enter-class="translate-y-2 opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="duration-100 ease-in transform"
    leave-class="translate-y-0 opacity-100"
    leave-to-class="translate-y-1 opacity-0"
  >
    <div v-if="active" :class="alertClass">
      <div class="alert-content">
        <div v-if="showIcon">
          <svg
            v-if="variant === 'info'"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12.75 7.71001V9.07801H11.25V7.71001H12.75ZM12.738 16.29H11.274V9.99001H12.738V16.29Z"
              fill="#2B8DEB"
            />
          </svg>
          <svg
            v-else-if="variant === 'success'"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM10.6365 14.0052L15.6818 8.90822L16.5 9.73472L10.6365 15.6582L7.5 12.4895L8.31825 11.6637L10.6365 14.0052Z"
              fill="#47A263"
            />
          </svg>
          <svg
            v-else-if="variant === 'danger'"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22 21L12 3L2 21H22ZM12.594 16.766H11.394C11.3817 16.457 11.3678 16.1394 11.3535 15.8132C11.2985 14.5559 11.238 13.1714 11.238 11.666V10.37H12.75V11.666C12.75 13.1714 12.6895 14.5558 12.6345 15.8132C12.6203 16.1393 12.6064 16.457 12.594 16.766ZM12.774 18.998H11.226V17.51H12.774V18.998Z"
              fill="#9C2D26"
            />
          </svg>
        </div>

        <p v-if="message">{{ message }}</p>
      </div>

      <svg
        v-if="dismissible"
        class="alert-close"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="closeAlert"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.14645 16.1464C6.95118 16.3417 6.95118 16.6583 7.14645 16.8536C7.34171 17.0488 7.65829 17.0488 7.85355 16.8536L12 12.7071L16.1464 16.8536C16.3417 17.0488 16.6583 17.0488 16.8536 16.8536C17.0488 16.6583 17.0488 16.3417 16.8536 16.1464L12.7071 12L16.8536 7.85355C17.0488 7.65829 17.0488 7.34171 16.8536 7.14645C16.6583 6.95118 16.3417 6.95118 16.1464 7.14645L12 11.2929L7.85355 7.14645C7.65829 6.95118 7.34171 6.95118 7.14645 7.14645C6.95118 7.34171 6.95118 7.65829 7.14645 7.85355L11.2929 12L7.14645 16.1464Z"
          fill="#0D0900"
        />
      </svg>
    </div>
  </transition>
</template>

<script setup>
import { removeElement } from '~/utils/alert'

const props = defineProps({
  isSmall: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: 'info',
  },
  dismissible: {
    type: Boolean,
    default: true,
  },
  dismissSecs: {
    type: Number,
    default: 5,
  },
  showIcon: {
    type: Boolean,
    default: true,
  },
  message: {
    type: String,
    default: 'Alert text',
  },
})

const active = ref(false)

const alertClass = computed(() => ({
  alert: true,
  'alert-dismissible': props.dismissible,
  'alert-sm': props.isSmall,
  'alert-base': !props.isSmall,
  'alert-info': props.variant === 'info',
  'alert-success': props.variant === 'success',
  'alert-danger': props.variant === 'danger',
}))

const closeAlert = () => {
  active.value = false
  setTimeout(() => {
    removeElement()
  }, 1000)
}

onMounted(() => {
  active.value = true
})

onUnmounted(() => {
  active.value = false
})
</script>

<style scoped lang="scss">
.alert {
  @apply border font-plain font-light text-xs leading-5 flex items-center justify-between;

  .alert-close {
    margin: 0 !important;
    cursor: pointer;
  }

  p {
    margin: 0;
  }

  .alert-content {
    @apply flex items-center;

    svg {
      @apply mr-[11px];
    }
  }

  &.alert-sm {
    @apply py-[10px] px-4;
  }

  &.alert-base {
    @apply py-[18px] px-4;
  }

  &.alert-info {
    @apply bg-info-100 border-info-200 text-charcoal;
  }

  &.alert-success {
    @apply bg-success-100 border-success-200 text-charcoal;
  }

  &.alert-danger {
    background-color: #f6e8e8;
    border-color: #e5bcbb;
  }
}
</style>
