import { SHOP_ROUTES_NAMES } from '~/constants'

const routeName = ref('')

export const useShopRouteName = () => {
  const setRouteName = (name) => {
    routeName.value = name
  }

  const isPageContainItem = () => {
    return (
      routeName.value === SHOP_ROUTES_NAMES.ITEM ||
      routeName.value === SHOP_ROUTES_NAMES.ITEM_DIAMOND_DETAILS ||
      routeName.value === SHOP_ROUTES_NAMES.ITEM_DIAMOND
    )
  }

  return {
    isPageContainItem,
    setRouteName,
    routeName,
  }
}
