export function browserIsIE() {
  const ua = window?.navigator.userAgent
  const msie = ua.indexOf('MSIE ')

  if (msie > 0 || !!window?.navigator.userAgent.match(/Trident.*rv\:11\./)) {
    // If Internet Explorer, return version number
    return true
  } else {
    return false
  }
}

export function browserIsSafari() {
  return /^((?!chrome|android).)*safari/i.test(window?.navigator.userAgent)
}
