<template>
  <div class="xl:container diamond-types">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 md:gap-8 mb-2">
      <template v-for="(item, index) in diamondItems" :key="index">
        <div class="mb-6 md:mb-12">
          <Image77
            class="bespoke-banner"
            :path="item.path"
            :m="item.imageXL"
            :s="item.imageM"
            :xs="item.imageS"
            :alt="item.alt"
            webp
            defer
            width="672"
            height="504"
          />

          <h3 class="heading-4 mt-3">{{ item.title }}</h3>
          <p class="body-14-aa mt-1.5 mb-3">{{ item.description }}</p>
          <!--          example with built in i18n Nuxt link -->
          <NuxtLinkLocale
            v-for="{ to, text } in item.ctaLinks"
            :key="to"
            :to="to"
            class="btn-link-sm mr-2"
            :data-ga4-category="item.ctaCategory"
            :data-di-id="item.ctaDiId"
          >
            {{ text }}
          </NuxtLinkLocale>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import Image77 from '@/components/ui/Image77.vue'
import { getDiamondSectionItems } from '@/constants/home'
 const { translateShopAspRouteToFriendlyUrl } = useAspRoutes()

const { t } = useI18n()
// Due to Nuxt i18n's design, which exposes translation functionality through composable exclusively,
// it's necessary to inject the translation function into our data-fetching method.
// This approach allows us to maintain reactivity and ensure our data is automatically updated with the correct translations
// whenever the active language changes.
const diamondItems = computed(() => getDiamondSectionItems(t,translateShopAspRouteToFriendlyUrl, (url) => url))
</script>
