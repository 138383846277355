import { CDN_PATH } from '~/constants'

const videoSizes: Record<string, number> = {
  XL: 1440,
  L: 1025,
  M: 769,
  S: 481,
  XS: 0,
}

const getVideoContent = (videoFile: string) => `
    <video id="HeroVideo" loop autoplay playsinline muted>
      <source src="${videoFile}" type="video/mp4" />
    </video>
  `

const getVideoFilename = (width: number, folderName: string, year: number): string => {
  const retinaSupport = width >= videoSizes.XL ? 'x2' : 'x1'
  const sizeKey = Object.keys(videoSizes).find((key) => width > videoSizes[key]) || videoSizes.M
  return `${CDN_PATH}/images/Campaign/${year}/${folderName}/${sizeKey}_Homepage_${retinaSupport}.mp4`
}

const addResponsiveHeroClass = () => {
  const heroElement = document.getElementById('hero')
  if (heroElement && !heroElement.classList.contains('responsive-hero')) {
    heroElement.classList.add('responsive-hero')
  }
}

export function appendVideo(folderName: string, elementClass: string, year: number) {
  const videoFile = getVideoFilename(window.innerWidth, folderName, year)
  const videoArea = document.querySelector(`#homepage #hero picture`) as HTMLElement

  // Avoid adding video twice
  if (!videoArea || videoArea.nextElementSibling?.tagName === 'VIDEO') {
    return
  }

  videoArea.insertAdjacentHTML('afterend', getVideoContent(videoFile))

  const videoElement = videoArea.nextElementSibling as HTMLVideoElement
  const deviceIsiOS = /iPad|iPhone|iPod/.test(navigator.platform)

  if (deviceIsiOS) {
    videoElement.controls = false
    videoElement.addEventListener('canplaythrough', () => {
      videoElement.play()
      addResponsiveHeroClass()
    })
  } else {
    videoElement.addEventListener('loadeddata', () => {
      addResponsiveHeroClass()
      videoArea.style.display = 'none' // hide image smoothly
    })

    videoElement.addEventListener('canplaythrough', () => {
      addResponsiveHeroClass()
    })
  }
}

export function loadVideo(folderName: string, elementClass: string, year: number) {
  const heroElement = document.getElementById('hero')
  const append = () => {
    window.removeEventListener('scroll', append)
    document.getElementById('hero')?.removeEventListener('mouseover', append)
    appendVideo(folderName, elementClass, year)
  }

  window.addEventListener('scroll', append)
  heroElement?.addEventListener('mouseover', append)
}

export function loadValentines2024() {
  loadVideo('Winter', 'valentines-2024', 2024)
}

export function loadBoxingDayAndNYEVideo() {
  loadVideo('BoxingDayAndNYE', 'new-year-sale', 2023)
}

export function loadChristmasSaleVideo() {
  loadVideo('Christmas/Homepage', 'christmas-sale', 2023)
}

export function loadBF2024() {
  loadVideo('Winter', 'valentines-2024', 2024)
}
