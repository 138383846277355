import { AUTH_PAGES_ROUTES } from '~/constants/routes'
import { AUTH_COOKIE_NAME } from '~/constants/cookies'
import useAuthStore from '~/store/auth'

export function useUserLogout() {
  const logout = () => {
    const localePath = useLocalePath()
    const authCookie = useCookie(AUTH_COOKIE_NAME)
    const authStore = useAuthStore()

    authStore
      .logout()
      .then(() => {
        authCookie.value = null

        const pathLocale = localePath(AUTH_PAGES_ROUTES.LOGIN)

        return navigateTo(pathLocale)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  return {
    logout,
  }
}
