type Payload = {
  id: string | number
  languageCode: string
}
// TODO: update after the prod API endpoint is available
const TRANSLATION_API = 'https://seventysevensandbox.blob.core.windows.net/translations-dev'

export async function fetchTranslation(payload: Payload): Promise<Record<string, string> | undefined> {
  const translationRoute = `${TRANSLATION_API}/${payload.languageCode}/${payload.id}.js`
  try {
    const response = await $fetch(translationRoute)
    const text = await response.text()

    return JSON.parse(text)
  } catch (error) {
    console.error('Failed to fetch translation', translationRoute)
    console.error(error)
    throw error // Rethrow to handle in the calling function
  }
}
