import { useNewSettingsStore } from '~/store/newSettingsStore'

interface UserPreferences {
  countryId: number
  currencyId: number
  languageId: number
  discountCode: string
}

export const useBasket = () => {
  const addToBasket = async ({
    category,
    subcategory,
    item,
    query,
  }: {
    category: string | number
    subcategory: string | number
    item: string
    query: Record<string, any>
  }) => {
    const { currentCountry, currentCurrency, currentLanguage } = storeToRefs(useNewSettingsStore())
    try {
      return await fetchWithLoader('/api/bag/add-item', {
        method: 'POST',
        body: {
          userPreference: {
            countryId: currentCountry.value?.Id,
            currencyId: currentCurrency.value?.Id,
            languageId: currentLanguage.value?.Id,
            discountCode: query['discount-code'],
          },
          ...getItemParams({ category, subcategory, item, query }),
          stock: query.stockNumber,
        },
      })
    } catch (e) {
      console.error(e)
      throw new Error(e)
    }
  }

  const addDiamondToBasket = async ({
    category,
    subcategory,
    item,
    shape,
    diamonds,
    query,
  }: {
    category: string | number
    subcategory: string | number
    item: string
    shape: string | number
    diamonds: string[]
    query: Record<string, any>
  }) => {
    const { currentCountry, currentCurrency, currentLanguage } = storeToRefs(useNewSettingsStore())
    try {
      return await fetchWithLoader('/api/bag/add-item', {
        method: 'POST',
        body: {
          userPreference: {
            countryId: currentCountry.value?.Id,
            currencyId: currentCurrency.value?.Id,
            languageId: currentLanguage.value?.Id,
            discountCode: query['discount-code'],
          },
          ...getItemParams({ category, subcategory, item, query }),
          itemId: item,
          subcategoryId: subcategory,
          shapeId: shape,
          selectedDiamonds: diamonds,
          discountCode: query['discount-code'],
        },
      })
    } catch (e) {
      console.error(e)
      throw new Error(e)
    }
  }

  const getRingSizes = async (payload) => {
    try {
      return await fetchWithLoader('/api/bag/get-ring-sizes', {
        body: payload,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const removeFromBasket = async (payload: { userPreferences: UserPreferences; uniqueCode: string }) => {
    console.log('payload', payload)
    try {
      return await fetchWithLoader('/api/bag/remove-item', {
        method: 'POST',
        body: payload,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const updateRingSize = async (payload: {
    userPreferences: UserPreferences
    uniqueCode: string
    ringSizeName: string
    ringSizeIndex: number
    ringSizeId: number
  }) => {
    try {
      return await fetchWithLoader('/api/bag/update-ring-size', {
        method: 'POST',
        body: payload,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const updateEngraving = async (payload: {
    userPreferences: UserPreferences
    editEngraving: {
      uniqueCode: string
      fontOptionId: number
      description: string
    }
  }) => {
    try {
      return await fetchWithLoader('/api/bag/update-engraving', {
        method: 'POST',
        body: payload,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const getBasket = async (payload: {
    userPreferences: UserPreferences
    country: number
    currency: number
    language: number
    discountCode: string
    diamondsInBag: unknown[]
    expressItemsInBag: unknown[]
    engravingsInBag: number
    url: string
  }) => {
    try {
      return await fetchWithLoader('/api/bag/get-basket', {
        method: 'POST',
        body: payload,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const processPage = async (payload: UserPreferences) => {
    try {
      return await fetchWithLoader('/api/bag/process-page', {
        method: 'POST',
        body: payload,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const applyDiscount = async (payload: UserPreferences) => {
    try {
      return await fetchWithLoader('/api/bag/apply-discount', {
        method: 'POST',
        body: payload,
      })
    } catch (e) {
      console.error(e)
      throw new Error(e)
    }
  }

  return {
    addToBasket,
    addDiamondToBasket,
    getRingSizes,
    removeFromBasket,
    updateRingSize,
    updateEngraving,
    getBasket,
    processPage,
    applyDiscount,
  }
}
