import { useNewSettingsStore } from '~/store/newSettingsStore'

export const getNaturalDiamondsFilters = () => {
  const { shopSettings } = useNewSettingsStore()
  return shopSettings.Filters.NaturalDiamondsFilters
}

export const getGemFilters = () => {
  const { shopSettings } = useNewSettingsStore()
  return shopSettings.Filters.GemFilters
}

export const getAttributesByStoneTypeFromSettings = () => {
  const { shopSettings } = useNewSettingsStore()
  return shopSettings.Attributes.AttributesByStoneType
}

export const getAttributesByCountry = () => {
  const { shopSettings } = useNewSettingsStore()
  return shopSettings.Attributes.AttributesByCountry
}

export const getExpressSettings = () => {
  const { expressSettings } = useNewSettingsStore()
  return expressSettings
}
