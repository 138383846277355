import { useLayoutStore } from '~/store/layoutStore'

// layout class constants
const COOKIE_BAR_CLASS = 'hasCookieNotice'

const BASIC_BOTTOM_OFFSET = 16
const MOBILE_COOKIE_BAR_HEIGHT = 50
const DESKTOP_COOKIE_BAR_HEIGHT = 40

export const useLayout = () => {
  const { isTabletOrDesktop } = useMQ()
  const { showCookieBar } = storeToRefs(useLayoutStore())
  watch(
    showCookieBar,
    (val) => {
      if (document) {
        if (val) {
          document.body.classList.add(COOKIE_BAR_CLASS)
        } else {
          document.body.classList.remove(COOKIE_BAR_CLASS)
        }
      }
    },
    { immediate: true },
  )

  // 16 px - base offset from bottom; 50, 40 - cookie bar height

  const bottomOffsetHeight = computed(() => {
    if (!showCookieBar) return BASIC_BOTTOM_OFFSET
    return (isTabletOrDesktop.value ? DESKTOP_COOKIE_BAR_HEIGHT : MOBILE_COOKIE_BAR_HEIGHT) + BASIC_BOTTOM_OFFSET
  })

  return {
    showCookieBar,
    bottomOffsetHeight,
  }
}
