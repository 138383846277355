import { alpha } from '@vuelidate/validators'

import { ACCENTS_ALPHA, TURKISH_ALPHA } from '~/constants/regex'

export const useValidators = () => {
  function isAlpha(text: string) {
    if (alpha.$validator(text, null, null)) return true
    if (TURKISH_ALPHA.test(text)) return true
    if (ACCENTS_ALPHA.test(text)) return true

    return false
  }

  return {
    isAlpha,
  }
}
