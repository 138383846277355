export const OmetriaHelper = {
  _basket: null,

  init(sessionId) {
    if (typeof window.ometria !== 'undefined' && typeof window.ometria.init === 'function') {
      this._basket = new window.ometria.Basket()
      this._basket.setId(sessionId)
    } else {
      console.error('Ometria SDK not found')
    }
  },

  addItem(id, total) {
    if (this._basket) {
      this._basket.addLineItem(id, 1, total)
    } else {
      console.error('Basket is not initialized')
    }
  },

  bulkAddItem(items) {
    if (this._basket) {
      items.forEach((item) => {
        this.addItem(item.OmetriaInfo, item.ItemPrice.TotalPrice.FinalPrice.NumericPrice.WithVat)
      })
    } else {
      console.error('Basket is not initialized')
    }
  },

  setTotal(total, currency) {
    if (this._basket) {
      this._basket.setTotal(total, currency)
    } else {
      console.error('Basket is not initialized')
    }
  },

  set() {
    if (this._basket && typeof window.ometria.setBasket === 'function') {
      window.ometria.setBasket(this._basket)
    } else {
      console.error('Basket is not initialized or setBasket function not found')
    }
  },

  trackProduct(itemId) {
    try {
      // TODO: update code below after this is done https://77diamonds.atlassian.net/browse/WV-1543
      if (typeof window.ometria.init === 'function') {
        window.ometria.init('product', { pid: String(itemId) })
      } else {
        console.error('Ometria init function not found')
      }
    } catch (err) {
      console.error('Error tracking product:', err)
    }
  },

  trackBasket(itemId) {
    try {
      if (typeof window.ometria.trackAddToBasket === 'function') {
        window.ometria.trackAddToBasket(String(itemId), 1)
      } else {
        console.error('Ometria trackAddToBasket function not found')
      }
    } catch (err) {
      console.error('Error tracking basket:', err)
    }
  },
}
