export enum STONE_TYPES {
  DIAMOND = 1,
  GEMSTONE = 2,
  LAB_GROWN = 3,
}

// for gemstones (stone with stoneType === 2)
export enum GEM_TYPES {
  RUBY = 366,
  SAPPHIRE = 367,
  EMERALD = 371,
}
