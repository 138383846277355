<template>
  <ui-button77 v-bind="$attrs" type="button" @click.native="goToShowroom(showroom)">
    <slot>
      {{
        showroom?.IsOpen || true
          ? $t('book-an-appointment', 'Book an appointment')
          : $t('keep-me-informed', 'Keep Me Informed ')
      }}
    </slot>
  </ui-button77>
</template>

<script>
const { eventBus, EVENTS } = useEventBus()

export default {
  name: 'OnlineAppointmentButton',
  props: {
    showroom: {
      type: Object,
      default: null,
    },
  },
  methods: {
    goToShowroom(showroom) {
      eventBus.emit(EVENTS.OPEN_BOOK_APPOINTMENTS, showroom)
    },
  },
}
</script>

<style scoped></style>
