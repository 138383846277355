export const useLayoutStore = defineStore('layoutStore', () => {
  const showCookieBar = ref(true) // TODO: investigate in what case we should display cookie bar, implement that logic
  const showGiveawayButton = ref(true)
  const showGiveaway = ref(true)
  const showExpressShoppingBar = ref(false)
  const showItemFinishAddToBag = ref(false)

  const kustomerChatWidget = ref(false)
  return {
    showCookieBar,
    kustomerChatWidget,
    showGiveawayButton,
    showExpressShoppingBar,
    showGiveaway,
    showItemFinishAddToBag,
  }
})
