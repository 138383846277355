
import * as diamondsRuntime$QxEE2mzcgQ from '/src/providers/diamonds.ts'
import * as shopRuntime$QxEE2mzcgQ from '/src/providers/diamonds.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "diamonds",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['diamonds']: { provider: diamondsRuntime$QxEE2mzcgQ, defaults: {"baseURL":"https://cdn.77diamonds.com"} },
  ['empty']: { provider: shopRuntime$QxEE2mzcgQ, defaults: {"baseURL":""} }
}
        