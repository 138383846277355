<template xmlns="http://www.w3.org/1999/html">
  <section class="bg-grey-100 mb-24 py-9 lg:py-12 md:px-24">
    <div class="review-slider container xl:px-32">
      <h5 class="body-12-aa-cap mb-6 text-center">{{ $t('what-our-customers-say', 'WHAT OUR CUSTOMERS SAY') }}</h5>

      <UiSwiper :config="reviewSwiperOptions">
        <SwiperSlide v-for="(review, index) in reviews" :key="index" class="slider-slide h-auto">
          <div class="slider-review">
            <div :class="reverseRatingWhenArabic">
              <div class="product-rating" :data-rating="review.Product.Rating"></div>
            </div>
            <h5 class="text-grey-900 text-xs mb-6">{{ review.Product.Description }}</h5>

            <template v-if="review.Service">
              <h4 class="product-review h5 mb-1 uppercase">{{ review.Service.Title.Translated }}</h4>
              <p class="product-review text-xs">
                {{ review.Service.Review.Translated }}
              </p>
            </template>
            <template v-else>
              <p class="product-review text-xs">
                {{ review.Product.Review.Translated }}
              </p>
            </template>

            <h4 class="text-xs uppercase mt-auto pt-6 md:pt-12">
              {{ review.CustomerName }},
              {{ getDaysAgoText(convertDateToDays(review.Service.PublishDate)) }}
            </h4>
          </div>
        </SwiperSlide>
      </UiSwiper>

      <div class="swiper-navigation-container hidden md:block xl:mx-28">
        <button type="button" class="swiper-btn-prev"></button>

        <button type="button" class="swiper-btn-next"></button>
      </div>
    </div>

    <div class="flex flex-wrap gap-x-4 justify-center items-center mt-4">
      <span class="h2">{{ $t('excellent', 'Excellent') }}</span>
      <i class="icon77" :class="`icon77-${getRatingIcon(averageRating)}`"></i>

      <div class="w-full sm:hidden"></div>

      <span class="body-14-aa">
        <span class="font-bold mr-1">{{ totalReviews }} </span>
        <span class="font-plain-medium">{{ $t('reviews-on', 'reviews on') }}</span>
      </span>

      <a
        href="https://www.trustpilot.com/review/www.77diamonds.com"
        target="_blank"
        data-di-id="di-id-96460dd9-8243954b"
      >
        <i class="icon77 icon77-Trustpilot_logo -mt-1.5"></i>
      </a>
    </div>
  </section>
</template>

<script setup lang="ts">
import { reviewSwiperOptions } from '@/constants/swiper/home'
import { fetchServiceReviews } from '~/api/shop'
import { useNewSettingsStore } from '~/store/newSettingsStore'
import { useArabicLanguage } from '~/composables/useArabicLanguage'

const { currentLanguage } = storeToRefs(useNewSettingsStore())
const { t, locale } = useI18n()

const totalReviews = ref(0)
const reviews = ref([])
const averageRating = ref(0)
const { applyArabic } = useArabicLanguage()
const reverseRatingWhenArabic = applyArabic('flex w-full justify-start flex-row-reverse')
const convertDateToDays = (date: string) => {
  return Math.floor((new Date() - new Date(date)) / (1000 * 60 * 60 * 24))
}
const getDaysAgoText = (days: number) => {
  if (days === 0) return t('today', 'today')
  if (days === 1) return t('yesterday', 'yesterday')
  return t('days-ago', { days: days.toString() }, '{days} days ago')
}

const getRatingIcon = (value: number) => {
  return value > 4.5 ? 'ratings_5' : 'ratings_4half'
}

const loadReviews = async () => {
  const response = await fetchServiceReviews({
    languageId: currentLanguage.value?.Id,
    reviewsPage: 1,
    reviewsPerPage: 5,
    orderBy: 'newest',
  })
  if (response) {
    totalReviews.value = response.NumberOfReviews
    reviews.value = response.Reviews
    averageRating.value = response.AverageRating
  }
}

watch(
  () => locale.value,
  () => {
    loadReviews()
  },
  { immediate: true },
)
</script>

<style lang="scss">
.review-slider {
  @import 'assets/scss/ReviewsSwiper.scss';

  position: relative;
  /*padding: 50px;*/

  .product-rating {
    color: #000;
    display: inline;
    position: relative;
    /*text-shadow: 0 0 1px black, 0 0 1px black;*/
    align-self: flex-start;

    &:after {
      content: '★★★★★';
    }

    &[data-rating='4.5'],
    &[data-rating='4'] {
      &:after {
        color: #ccc;
      }
      &:before {
        content: '★★★★★';
        position: absolute;
        overflow: hidden;
      }
    }

    &[data-rating='4']:before {
      width: 80%;
    }

    &[data-rating='4.5']:before {
      width: 90%;
    }
  }

  .slider-slide {
    min-width: 100%;
  }

  .slider-review {
    margin: 0 8px;
    padding: 32px;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .product-review {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media only screen and (min-width: 768px) {
    -webkit-line-clamp: 8;
  }

  @media only screen and (min-width: 768px) {
    .slider-slide {
      /*margin: 0 12px;*/
      min-width: 50%;
    }

    .slider-navigation {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
    }

    .slider-navigation-item {
      position: absolute;
      right: 100%;

      border-radius: 96px;
      background: rgba(255, 255, 255, 0.8);
      padding: 12px;

      &:after {
        border: 1px solid white;
        border-radius: 0;
        border-width: 0 0 1px 1px;
        border-color: black;
        background: none;
        transform: rotate(45deg);
        margin: 7px 5px 7px 9px;
      }

      &.active,
      &.active + .slider-navigation-item ~ .slider-navigation-item {
        display: none;
      }

      &.active + .slider-navigation-item {
        left: 100%;
        right: unset;

        &:after {
          //border-width: 1px 1px 0 0;
          margin-left: 5px;
          margin-right: 9px;
          transform: rotate(225deg);
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .slider-slide {
    }

    .slider-review {
      margin: 0 16px;
    }
  }

  @media only screen and (min-width: 1440px) {
    .slider-slide {
      min-width: 33.33%;
      aspect-ratio: 3/4;
    }

    .product-review {
      -webkit-line-clamp: 13;
    }
  }
}
</style>
