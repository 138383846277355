<script setup lang="ts">
import { useRouter } from 'vue-router'

const router = useRouter()
const localePath = useLocalePath()

const isDevelopment = process.dev

const props = defineProps({
  error: {
    type: Object,
    default: () => ({}),
  },
})

onBeforeMount(() => {
  console.error('Error page details:', { ...props.error })

  if (!isDevelopment) {
    router.replace(localePath('/notFound.aspx'))
  }
})
</script>

<template>
  <div
    v-if="isDevelopment"
    class="flex flex-col items-center justify-center min-h-screen bg-gray-700 text-gray-700 p-4"
  >
    <div class="bg-white shadow-md rounded-lg p-6 max-w-lg w-full text-center">
      <h1 class="text-4xl font-bold text-red-500">
        {{ error.statusCode || 'Error' }}
      </h1>
      <p class="mt-4 text-lg">
        {{ error.message || 'An unexpected error occurred.' }}
      </p>
      <div class="mt-6">
        <h2 class="text-xl font-medium text-gray-800">Details:</h2>
        <pre class="mt-2 p-4 bg-gray-100 rounded-md text-left text-sm text-gray-600 overflow-x-auto">
          {{ JSON.stringify(props.error, null, 2) }}
        </pre>
      </div>
      <div class="mt-6 flex gap-4 justify-center">
        <button
          class="bg-gray-200 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-300 focus:ring-2 focus:ring-gray-300"
          @click="
            () => {
              clearError()
              router.back()
            }
          "
        >
          Go Back
        </button>
        <NuxtLinkLocale
          to="/"
          class="bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 focus:ring-2 focus:ring-gray-500"
        >
          Go Home
        </NuxtLinkLocale>
      </div>
    </div>
  </div>
</template>
