import { DEFAULT_LOCALE, DEFAULT_EN_COUNTRY_CODE } from '~/constants'
import { useNewSettingsStore } from '~/store/newSettingsStore.ts'

const getLanguage = () => {
  const settings = useNewSettingsStore()
  return settings?.currentLanguage || getI18nLanguageId()
}

export const getCurrentLangCode = () => {
  return getLanguage().Code || DEFAULT_LOCALE
}

export const getCurrentSelectedLanguage = () => {
  const langCode = getCurrentLangCode()
  return langCode === DEFAULT_LOCALE ? DEFAULT_EN_COUNTRY_CODE : langCode
}

export const getLanguageCodeFromRoute = (route) => {
  const { $i18n } = useNuxtApp()
  const i18nLanguageCode = getLanguageCodeFromUrl(route)
  return $i18n.locales.value.find((locale) => locale.code === i18nLanguageCode)?.code || DEFAULT_LOCALE
}

export const getLanguageCodeFromUrl = (route) => {
  return route.path.split('/')[1]
}

export const getI18nLanguageId = () => {
  const { $i18n } = useNuxtApp()

  return $i18n.locales.value.find((locale) => locale.code === $i18n.locale.value)?.id || 1
}

export const getLocalCode = () => {
  return getLanguage().LocalCode
}
