<template>
  <div class="accordion-item" :class="{ '-opened': visible, dirty: dirty }">
    <h3 class="accordion-item-label" :class="computedTitleClass" @click="toggle">
      {{ title }}
      <slot name="label" v-bind="{ visible }"></slot>
      <i v-if="showArrow" class="accordion-item-arrow icon77shop" :class="computedIconClass"></i>
    </h3>
    <collapse :visible="visible" :innerClass="computedContentClass">
      <slot />
    </collapse>
  </div>
</template>

<script setup>
import Collapse from '../Collapse'
const emits = defineEmits(['activated'])

const props = defineProps({
  value: {},
  title: { type: String, required: true },
  titleClass: { type: String, required: false },
  contentClass: { type: String, required: false },
  opened: { type: Boolean, default: false },
  showArrow: { type: Boolean, default: true },
  toggleable: { type: Boolean, default: true },
  onClose: { type: Function, required: false },
  timer: { default: false },
})

const visible = ref(props.opened)
const dirty = ref(props.opened)

const toggleVisibility = inject('toggleVisibility')
const registerItem = inject('registerItem')

// Register the item with the parent
onMounted(() => {
  registerItem({
    value: props.value,
    visible,
    titleClass: props.titleClass,
    contentClass: props.contentClass,
    emits,
  })
})

watch(
  () => visible.value,
  () => {
    if (visible.value === false) props.onClose && props.onClose()
  },
)

const computedTitleClass = computed(() => [
  props.titleClass,
  inject('titleClass'),
  props.toggleable === false ? 'cursor-default' : '',
])
const computedContentClass = computed(
  () => `accordion-item-content ${props.contentClass || ''} ${inject('contentClass') || ''}`,
)
const computedIconClass = computed(() => [props.showArrow !== true, visible.value ? 'chevron-up' : 'chevron-down'])

const toggle = () => {
  if (props.toggleable === false) return

  toggleVisibility({ value: props.value, visible })

  dirty.value = true

  if (props.timer) {
    startTimer()
  }
}

const startTimer = () => {
  if (visible.value) {
    setTimeout(() => {
      visible.value = false
    }, 3000)
  }
}
</script>

<style lang="scss">
.accordion-item {
  &-label {
    cursor: pointer;
    margin-bottom: 0;
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    margin-top: -1px;
  }

  .icon77shop {
    font-size: 15px;
  }
}
</style>
