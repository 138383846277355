import { Navigation, Pagination } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'

export const bestSellerSwiperOptions: SwiperOptions = {
  modules: [Pagination],
  slidesPerView: 2,
  pagination: { clickable: true },
  breakpoints: {
    2560: {
      slidesPerView: 5,
    },
    1024: {
      slidesPerView: 5,
    },
    768: {
      slidesPerView: 5,
    },
    480: {
      slidesPerView: 3,
    },
    376: {
      slidesPerView: 2,
    },
  },
}
interface ProductImage {
  Id: number
  Path: string
  Alt: string
  Title: string
  CaratSize: string
  ApproximateCaratSize: boolean
  IsActive: boolean
  PositionIndex: number
  Properties: Record<string, string>
}

interface DiscountInfo {
  Code: string
  Type: number
  Value: number
}
interface DiamondDiscountInfo extends DiscountInfo {
  UniqueCode: string
}
interface NumericPrice {
  WithVat: number
  WithoutVat: number
  Vat: number
}
interface WebPrice {
  WithVat: string
  WithoutVat: string
  Vat: string
}

interface PriceInfo {
  NumericPrice: NumericPrice
  WebPrice: WebPrice
}
interface Price {
  Price: PriceInfo
  DiscountedPrice: PriceInfo
  SavingsPrice: PriceInfo
  FinalPrice: PriceInfo
}

interface SmallStonesPrice extends Price {
  Description: string
}
interface DiamondsPrice extends Price {
  UniqueCode: string
}

interface ProductItemPrice {
  CurrencySymbol: string
  VatValue: number
  SettingPrice: Price
  TotalDiamondPrice: Price
  TotalSmallStonePrice: Price
  DiamondsPrice: DiamondsPrice[]
  SmallStonesPrice: SmallStonesPrice[]
  TotalPrice: Price
  SettingDiscountInfo: DiscountInfo
  DiamondsDiscountInfo: DiamondDiscountInfo[]
  SmallStonesDiscountInfo: DiscountInfo[]
  ItemDiscountInfo: DiscountInfo
  HasDiscount: boolean
  NeedsBespokeQuote: boolean
  MixedMetalColletSalePriceIncVAT: number
}
export interface BestSellerSwiperSignature {
  BaseUrl: string
  Image: ProductImage
  ItemId: string
  ItemPrice: ProductItemPrice
  Metal: number
  Name: string
}

export const reviewSwiperOptions = {
  modules: [Navigation, Pagination],
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
  pagination: {
    clickable: true,
    paginationDisabledClass: 'hide-pagination',
  },
  initialSlide: 1,
  slidesPerView: 1,
  breakpoints: {
    1440: {
      slidesPerView: 3,
      navigation: { enabled: true },
      pagination: { enabled: false },
    },
    768: {
      slidesPerView: 2,
      navigation: { enabled: true },
      pagination: { enabled: false },
    },
    0: {
      slidesPerView: 1,
      navigation: { enabled: false },
      pagination: { enabled: true },
    },
  },
}

const imagePath = '/images/Homepage/Categories'
// TODO: confirm info, on backend image looks like  imagePath + '/{size}ER_x{ratio}.png', seems like current requireImg function do not handle it properly
//<%= product.Image.Replace("{s}", "M-").Replace("{r}", "1") %> 144w,
//<%= product.Image.Replace("{s}", "M-").Replace("{r}", "2") %> 288w,
//<%= product.Image.Replace("{s}", "").Replace("{r}", "1") %> 250w,
//<%= product.Image.Replace("{s}", "").Replace("{r}", "2") %> 500w"
export const getCategorySwiperSignature = (i18nTranslation) => [
  {
    title: i18nTranslation('engagement-rings', 'Engagement Rings'),
    link: '/ShopNew/Default.aspx?category=1',
    image: imagePath + '/ER_x{ratio}.png',
    alt: i18nTranslation('category-slider-eng-ring', 'Close-up of a woman\'s hand wearing an elegant engagement ring'),
  },
  {
    title: i18nTranslation('ready-to-wear', 'Ready To Wear'),
    link: '/ShopNew/Default.aspx?category=12&filter=1',
    image: imagePath + '/RTW_x{ratio}.png',
    alt: i18nTranslation('category-slider-ready-to-wear', 'Profile view of a woman wearing a stylish earring and multiple rings.'),
  },
  {
    title: i18nTranslation('eternity-rings', 'Eternity Rings'),
    link: '/ShopNew/Default.aspx?category=3',
    image: imagePath + '/ET_x{ratio}.png',
    alt: i18nTranslation('category-slider-eternity-ring', 'Hand adorned with sparkling eternity rings resting against a luxurious dark fabric.'),
  },
  {
    title: i18nTranslation('earrings', 'Earrings'),
    link: '/ShopNew/Default.aspx?category=5',
    image: imagePath + '/EAR_x{ratio}.png',
    alt: i18nTranslation('category-slider-earring', 'Elegant earrings with a teardrop design showcased on a woman'),

  },
  {
    title: i18nTranslation('necklaces', 'Necklaces'),
    link: '/ShopNew/Default.aspx?category=4&subcategories=8,9',
    image: imagePath + '/NECK_x{ratio}.png',
    alt: i18nTranslation('category-slider-necklace', 'Side profile of a woman wearing a sleek necklace'),

  },
  {
    title: i18nTranslation('bracelets', 'Bracelets'),
    link: '/ShopNew/Default.aspx?category=6&subcategory=13',
    image: imagePath + '/BRA_x{ratio}.png',
    alt: i18nTranslation('category-slider-bracelet', 'A woman\'s hands crossed, wearing a delicate bracelet'),

  },
  {
    title: i18nTranslation('cocktail-rings', 'Cocktail rings'),
    link: '/ShopNew/Default.aspx?category=6&subcategory=12',
    image: imagePath + '/CR_x{ratio}.png',
    alt: i18nTranslation('category-slider-cocktail-ring', 'Close-up of a woman\'s hand adorned with a bold blue gemstone cocktail ring and a sparkling diamond ring'),

  },
  {
    title: i18nTranslation('wedding-rings', 'Wedding Rings'),
    link: '/ShopNew/Default.aspx?category=2',
    image: imagePath + '/WED_x{ratio}.png',
    alt: i18nTranslation('category-slider-wedding-ring', 'Two hands intertwined, showcasing matching wedding rings on a light and dark skin tone'),

  },
]

export const categorySwiperOptions = {
  modules: [Pagination],
  slidesPerView: 1,
  pagination: { clickable: true },
  breakpoints: {
    2560: {
      slidesPerView: 9,
    },
    1024: {
      slidesPerView: 5,
    },
    768: {
      slidesPerView: 5,
    },
    480: {
      slidesPerView: 3,
    },
    376: {
      slidesPerView: 2,
    },
  },
}
