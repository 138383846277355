<template>
  <component
    :is="computedTag"
    :disabled="disabled || loading"
    type="button"
    class="items-center justify-center transition duration-150 ease-in-out custom-button border-1 border-black disabled:cursor-not-allowed disabled:text-Grey-700 disabled:hover:text-Grey-700"
    :class="[
      `variant--${variant}`,
      rounded ? 'rounded-sm' : '',
      block ? 'flex w-full' : 'inline-flex',
      small ? 'small' : '',
      buttonClass,
      lowercase ? 'body-12-aa' : 'body-12-aa-cap',
    ]"
    :to="to"
    :href="href"
  >
    <svg
      v-if="loading"
      class="w-5 h-5 mr-3 -ml-1 animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>

    <slot></slot>
  </component>
</template>

<script setup lang="ts">
const props = defineProps({
  block: {
    type: Boolean,
    default: false,
  },
  lowercase: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: 'default',
    validator: (val: string) => ['default', 'outlined', 'outlined-reversed', 'yellow', 'flat', 'link'].includes(val),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  buttonClass: {
    type: String,
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  tag: {
    type: String,
    default: '',
  },
  to: {
    type: String,
    default: '',
  },
  href: {
    type: String,
    default: '',
  },
})

const computedTag = computed(() => {
  if (props.tag) return props.tag
  if (props.to || props.href) return resolveComponent('NuxtLink')
  return 'button'
})
</script>

<style lang="scss">
.custom-button {
  transition: 0.3s all linear;
  padding: 12px 25px;
  &:not(.variant--link) {
    @apply no-underline;
  }

  &.variant {
    &--default {
      color: #ffffff;
      background-color: #0d0900;
      border: 1px solid transparent;

      &:hover {
        .icon77 {
          filter: invert(1);
        }
        background-color: white;
        color: #0d0900;
        border: 1px solid #0d0900;
      }

      &:disabled {
        background-color: #0d09001f;
        color: #ffffff;
        border: 1px solid transparent;
      }
    }

    &--outlined {
      color: #0d0900;
      background-color: #fff;
      border: 1px solid #0d0900;

      &:hover {
        .icon77 {
          filter: invert(1);
        }
        background-color: #0d0900;
        color: #fff;
        border: 1px solid #0d0900;
      }
    }

    &--outlined-reversed {
      color: #fff;
      border: 1px solid #fff !important;
      background: transparent;

      &:hover {
        background-color: #fff;
        color: #0d0900;
        border: 1px solid #fff;
      }
    }

    &--yellow {
      color: #000;
      border: 1px solid #ffc13a;
      background-color: #ffc13a;
      &:hover {
        background-color: #666;
        border-color: #666;
        color: #fff;
      }
    }
    &--flat {
    }

    &--link {
      padding: 0;
      text-decoration: underline;
      text-transform: none;
      letter-spacing: 0.3px;
      font-size: 14px;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  &.small {
    min-height: 32px;
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    // border-radius: 2px;

    &.variant {
      &--link {
        text-decoration: none;
        font-size: 12px;
        @apply tracking-wide;
      }
    }
  }
}
</style>
