<script setup lang="ts">
import useUserStore from '~/store/user'
import { useUserLogout } from '~/composables/useUserLogout'
import Banner77 from '~/components/ui/Banner77.vue'
import { PRIVATE_PAGES_ROUTES } from '~/constants/routes'

const PROFILE_LABEL = 'my-profile'

defineI18nRoute({
  paths: { page_id: '/755/756/757/758/759' },
})

const route = useRoute()
const router = useRouter()
const { translate } = useTranslations()
const localePath = useLocalePath()

const userStore = useUserStore()

const { logout } = useUserLogout()

const currentMenuLabel = ref(PROFILE_LABEL)

const menuList = reactive([
  {
    icon: 'user',
    label: 'my-profile',
    showOnMobile: true,
    title: translate('my-profile', 'My Profile'),
    url: localePath(PRIVATE_PAGES_ROUTES.PROFILE),
  },
  {
    icon: 'delivery-box',
    label: 'my-orders',
    showOnMobile: true,
    title: translate('my-orders', 'My Orders'),
    url: localePath(PRIVATE_PAGES_ROUTES.ORDERS),
  },
  {
    icon: 'map-marker',
    label: 'my-addresses',
    showOnMobile: true,
    title: translate('my-addresses', 'My Addresses'),
    url: localePath(PRIVATE_PAGES_ROUTES.ADDRESS),
  },
  {
    icon: 'lock',
    label: 'privacy-settings',
    showOnMobile: true,
    title: translate('privacy-settings', 'Privacy Settings'),
    url: localePath(PRIVATE_PAGES_ROUTES.PRIVACY),
  },
  {
    icon: 'log-out',
    label: 'sign-out',
    showOnMobile: true,
    title: translate('sign-out', 'Sign Out'),
    onClick: () => logout(),
  },
])

function handleChangeActiveMenu(menuItem) {
  if (menuItem.onClick) {
    menuItem.onClick()
    return
  }

  if (menuItem.url) {
    currentMenuLabel.value = menuItem.label
    router.push(menuItem.url)
  }
}

const handleChangeActiveMenuByLabel = (itemLabel) => {
  const menuItem = menuList.find((item) => item.label === itemLabel)

  handleChangeActiveMenu(menuItem)
}

const getCurrentTitle = () => menuList.find((item) => item.url === route.path)?.title ?? menuList[0].title

onMounted(() => {
  const initialMenu = menuList.find(
    (item) => item.url && route.params.urlName && item.url.includes(route.params.urlName),
  )

  if (initialMenu && initialMenu.label !== currentMenuLabel.value) {
    handleChangeActiveMenu(initialMenu)
  }
})
</script>

<template>
  <div>
    <Banner77
      class="header-banner hidden md:block"
      path="/images/Campaign/2021/Autumn/Category/"
      xl="1920_LP_WED_x{ratio}.jpg"
      l="1440_LP_WED_x{ratio}.jpg"
      m="1024_LP_WED_x{ratio}.jpg"
      s="768_LP_WED_x{ratio}.jpg"
      xs="375_LP_WED_x{ratio}.jpg"
      :webp="true"
    >
      <div class="container">
        <div class="block">
          <h2
            v-if="userStore.user?.firstName && route.path === PRIVATE_PAGES_ROUTES.PROFILE"
            class="text-center text-white heading-2"
          >
            {{ translate('welcome-user', 'Welcome {name} ') + userStore.user?.firstName }}
          </h2>
          <h2 v-else class="text-center text-white heading-2 capitalize">
            {{ getCurrentTitle() }}
          </h2>
        </div>
      </div>
    </Banner77>
    <!--small screens-->
    <div class="py-6 px-4 md:p-6 block md:hidden">
      <h2 v-if="userStore.user?.firstName" class="text-left heading-4 mb-4 capitalize">
        {{ translate('welcome-user', 'Welcome {name} ') + userStore.user?.firstName }}
      </h2>
      <select
        id="links"
        v-model="currentMenuLabel"
        class="w-full px-4 bg-white border border-solid links-select border-darkLighter-400 h-11 body-14-aa"
        name="links"
        @change="handleChangeActiveMenuByLabel($event.target.value)"
      >
        <option v-for="(item, index) in menuList.filter((link) => link.showOnMobile)" :key="index" :value="item.label">
          {{ item.title }}
        </option>
      </select>
    </div>
    <!--from medium screens-->
    <div class="p-4 md:py-8 grid77 body-14-aa bg-pale-100 md:bg-white xl:container">
      <nav class="hidden md:block col-span-3">
        <div v-for="(item, index) in menuList" :key="index" class="my-3 menu-link">
          <i class="icon77 mr-3" :class="`icon77-${item.icon}`" />
          <button :class="{ underline: item.url && item.url === route.path }" @click="handleChangeActiveMenu(item)">
            {{ item.title }}
          </button>
        </div>
      </nav>
      <div v-if="route.path" class="col-span-12 md:col-span-9">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.header-banner {
  .links-select {
    background-image: url('https://cdn.77diamonds.com/images/shop/icons/chevron-down.svg');
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
  }
}
.menu-link {
  a {
    text-decoration: none;
  }
  a.router-link-exact-active {
    text-decoration: underline;
  }
}
</style>
