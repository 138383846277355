export enum CATEGORIES_ENUM {
  ENGAGEMENT_RINGS = 1,
  WEDDING_RINGS = 2,
  ETERNITY_RINGS = 3,
  DIAMOND_NECKLACES = 4,
  DIAMOND_EARRING = 5,
  DIAMOND_JEWELLERY = 6,
  LOOSE_DIAMONDS = 7,
  EDUCATION = 8,
  OLD_ENGAGEMENT_RINGS = 9,
  JEWELLERY = 10,
  COLLECTIONS = 11,
  EXPRESS = 12,
}
