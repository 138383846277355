const useChatStore = defineStore('chat', {
  state: () => ({
    isLiveHelpOpen: false,
  }),
  actions: {
    toggleIsLiveHelpOpen() {
      this.isLiveHelpOpen = !this.isLiveHelpOpen
    },
  },
})

export default useChatStore
