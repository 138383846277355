export const useMQ = () => {
  const { $viewport } = useNuxtApp()

  const isDesktop = computed(() => !['xs', 'sm'].includes($viewport.breakpoint.value))
  const isTablet = computed(() => $viewport.breakpoint.value === 'sm')
  const isMobile = computed(() => $viewport.breakpoint.value === 'xs')

  const isMobileOrTablet = computed(() => isMobile.value || isTablet.value)
  const isTabletOrDesktop = computed(() => isTablet.value || isDesktop.value)
  return {
    isDesktop,
    isTablet,
    isMobile,

    isMobileOrTablet,
    isTabletOrDesktop,
  }
}
