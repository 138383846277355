export default [
  'á', //	&aacute
  'â', //	&acirc
  'à', //	&agrave
  'å', //	&aring
  'ã', //	&atilde
  'ä', //	&auml
  'æ', //	&aelig

  'é', //	&eacute
  'ê', //	&ecirc
  'è', //	&egrave
  'ë', //	&euml

  'ï', //	&iuml
  'í', //	&iacute
  'î', //	&icirc
  'ì', //	&igrave

  'ö', //	&ouml
  'ó', //	&oacute
  'ô', //	&ocirc
  'ò', //	&ograve
  'ø', //	&oslash
  'õ', //	&otilde

  'ù', //	&ugrave
  'ú', //	&uacute
  'û', //	&ucirc
  'ü', //	&uuml

  'ý', //	&yacute
  'ç', //	&ccedil
  'ð', //	&eth
  'ß', //	&szlig
  'ñ', //	&ntilde
  'þ', //	&thorn

  'ğ',
  'ş',
].join('')
