import { useLayoutStore } from '~/store/layoutStore'

export default defineNuxtPlugin((nuxtApp) => {
  const { kustomerChatWidget } = storeToRefs(useLayoutStore(nuxtApp.$pinia))

  if (process.env.NODE_ENV == 'development') return

  nuxtApp.hook('app:mounted', () => {
    window.addEventListener('kustomerLoaded', () => {
      if (window.Kustomer) {
        const lang = nuxtApp.$i18n.locale.value
        const langIsoCode = lang === 'en' ? 'en-GB' : lang

        window.Kustomer.start(
          {
            brandId: '628d33431fad7be335db0aa7',
            chatIconSize: {
              width: 0,
              height: 0,
            },
            hideChatIcon: true,
            lang: langIsoCode,
            preferredView: 'chat',
          },
          () => {
            kustomerChatWidget.value = true
            console.log('kustomer chat has been initialized')
            // if (window.openKustomer) {
            //   window.Kustomer.open()
            // }
          },
        )
      } else {
        console.error('Kustomer script failed to load.')
      }
    })
  })

  nuxtApp.hook('i18n:beforeLocaleSwitch', async ({ oldLocale, newLocale, context }) => {
    if (window.Kustomer) {
      const langIsoCode = newLocale === 'en' ? 'en-GB' : newLocale
      window.Kustomer.stop()

      window.Kustomer.start(
        {
          brandId: '628d33431fad7be335db0aa7',
          chatIconSize: {
            width: 0,
            height: 0,
          },
          hideChatIcon: true,
          lang: langIsoCode,
          preferredView: 'chat',
        },
        () => {
          kustomerChatWidget.value = true
          console.log('kustomer chat has been initialized')
          if (window.openKustomer) {
            window.Kustomer.open()
          }
        },
      )
    }
  })

  useHead({
    script: {
      src: '//cdn.kustomerapp.com/chat-web/widget.js',
      async: true,
      defer: true,
      'data-kustomer-api-key': 'eyJhbGciOiJub25lIn0.eyJvcmdOYW1lIjoiNzdkaWFtb25kcyIsInJvbGVzIjpbIm9yZy50cmFja2luZyJdfQ',
      body: true,
    },
  })
})
