export const SOCIAL_MEDIA = {
  WHATSAPP: {
    LINK: 'https://wa.me/442035401477',
    NAME: 'Whatsapp',
    SPRITE_CLASS: 'icon77-whatsapp',
  },
  INSTAGRAM: {
    LINK: 'http://instagram.com/77diamonds',
    NAME: 'Instagram',
    SPRITE_CLASS: 'icon77-instagram',
  },
  FACEBOOK: {
    LINK: 'https://www.facebook.com/77Diamonds',
    NAME: 'Facebook',
    SPRITE_CLASS: 'icon77-facebook',
  },
  X: {
    LINK: 'https://x.com/77Diamonds',
    NAME: 'X',
    SPRITE_CLASS: 'icon77-social-X',
  },
  PINTEREST: {
    LINK: 'https://www.pinterest.com/77Diamonds',
    NAME: 'Pinterest',
    SPRITE_CLASS: 'icon77-pinterest',
  },
  LINKEDIN: {
    LINK: 'https://www.linkedin.com/company/77-diamonds',
    NAME: 'Linkedin',
    SPRITE_CLASS: 'icon77-linkedin',
  },
  YOUTUBE: {
    LINK: 'https://www.youtube.com/c/77Diamonds',
    NAME: 'Youtube',
    SPRITE_CLASS: 'icon77-youtube',
  },
  TIKTOK: {
    LINK: 'https://www.tiktok.com/@77diamondsltd',
    NAME: 'Tiktok',
    SPRITE_CLASS: 'icon77-social-tiktok-24px',
  },
}
