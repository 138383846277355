<template>
  <div class="customerService basis-1/3 relative">
    <button
      id="mobile-nav"
      type="button"
      class="lg:hidden p-4 -ml-2 -my-4"
      aria-label="toggle menu"
      @click="$emit('open-mobile-menu')"
    >
      <i class="icon77 icon77-nav_menu _mr-1"></i>
    </button>

    <div class="lg:inline-block hidden">
      <HeaderCurrencySelection class="CurrencySelectionTopbar" />
    </div>
    <div ref="dropdownContainerRef" data-dropdown="#helpDropdown" class="inline relative dropdown-wrapper">
      <button
        type="button"
        class="lg:p-2 lg:-my-2"
        :aria-label="$t('need-help-menu', 'Need Help Menu')"
        @click="displayDropdown = !displayDropdown"
      >
        <i class="icon77 icon77-bell mr-2"></i>
        <span class="body-12-aa hidden md:inline">{{ $t('need-help', 'Need Help?') }}</span>
      </button>

      <div
        v-if="displayDropdown"
        id="helpDropdown"
        ref="dropdownRef"
        data-static
        class="dropdown help-dropdown p-4 mt-3"
      >
        <h3 class="heading-6 text-center">{{ $t('need-help', 'Need Help?') }}</h3>

        <hr class="my-3" />

        <NuxtLink :href="'tel:' + countryPhoneNumber" class="block no-underline rulertel mb-2">
          <i class="icon77 icon77-phone mr-2"></i>
          <span class="body-12-aa">⁦{{ countryPhoneNumber }}⁩</span>
        </NuxtLink>

        <NuxtLink href="https://wa.me/442035401477" target="_blank" class="block no-underline mb-2">
          <i class="icon77 icon77-whatsapp mr-2"></i>
          <span class="body-12-aa">{{ $t('whatsapp-us', 'WhatsApp Us') }}</span>
        </NuxtLink>

        <button
          class="_btn _p-0"
          open-book-appointment="1"
          book-appointment-origin="header"
          @click="handleBookAppointment"
        >
          <i class="icon77 icon77-nav_location mr-2"></i>
          <span class="body-12-aa">{{ $t('book-an-appointment', 'Book an Appointment') }}</span>
        </button>
      </div>
    </div>

    <slot />
  </div>
</template>

<script setup lang="ts">
import HeaderCurrencySelection from './header-currency-sellection'
import { useNewSettingsStore } from '~/store/newSettingsStore'

const { eventBus, EVENTS } = useEventBus()

const { countryPhoneNumber } = storeToRefs(useNewSettingsStore())
const displayDropdown = ref(false)
const dropdownContainerRef = ref<HTMLElement>()

onClickOutside(dropdownContainerRef, () => {
  displayDropdown.value = false
})

function handleBookAppointment() {
  eventBus.emit(EVENTS.OPEN_BOOK_APPOINTMENTS)
}
</script>

<style scoped lang="scss">
.help-dropdown {
  min-width: 200px;
  box-shadow:
    0px 0px 12px -1px rgba(13, 9, 0, 0.1),
    0px 1px 4px -1px rgba(13, 9, 0, 0.06);

  h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    @apply font-domaine;
  }

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: -5px;
    left: 8px;
    transform: rotate(-45deg);
    z-index: 1000;
    background: #fff;
    box-shadow: 3px -3px 7px -2px rgba(13, 9, 0, 0.1);
  }

  @media (min-width: 1024px) {
    left: 50%;
    transform: translateX(-50%);

    &:before {
      left: calc(50% - 10px);
    }
  }
}
</style>
