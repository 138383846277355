const LOCATIONS_CODE_MAP = {
  ar: 'ar-AE',
  bg: 'bg-BG',
  cs: 'cs-CZ',
  dk: 'da-DK',
  nl: 'nl-NL',
  en: 'en-GB',
  fi: 'fi-FI',
  fr: 'fr-FR',
  de: 'de-DE',
  hu: 'hu-HU',
  it: 'it-IT',
  cn: 'zh-CN',
  no: 'no-NO',
  pl: 'pl-PL',
  pt: 'pt-PT',
  ro: 'ro-RO',
  ru: 'ru-RU',
  es: 'es-ES',
  se: 'sv-SE',
}

type AvailableDateFormats = number | Date | string

export const useDateFormat = () => {
  const { t, locale } = useI18n()

  const locationCode = computed<string>(() => {
    return LOCATIONS_CODE_MAP[locale.value] || navigator.language
  })

  function formatDate(value: AvailableDateFormats, format?: Intl.DateTimeFormatOptions) {
    const date = new Date(value)

    return date.toLocaleDateString(locationCode.value, format)
  }

  const defaultDaysElapsedOptions = { maxDays: 50 }

  function getDaysElapsed(value: AvailableDateFormats, { maxDays } = defaultDaysElapsedOptions) {
    const date = new Date(value)

    const days = Math.floor((new Date().getTime() - date.getTime()) / 1000 / 60 / 60 / 24)

    if (days == 0) {
      return t('today', 'Today')
    }
    if (days == 1) {
      return t('yesterday', 'Yesterday')
    }

    if (days <= maxDays) {
      const translation = t('days-ago', 'days ago')
      return `${days} ${translation}`
    }

    return formatDate(value)
  }

  return {
    formatDate,
    locationCode,
    getDaysElapsed,
  }
}
