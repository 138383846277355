import { get } from 'lodash-es'

export default defineI18nConfig(() => ({
  legacy: false,
  missingWarn: false,
  fallbackWarn: false,
  // Prevent '@' character from being used as linked messages
  messageResolver: (obj, path) => {
    // console.log(obj, path)
    return get(obj, path, '')?.replaceAll(/@/g, "{'@'}") || null
  },
  detectBrowserLanguage: false,
}))
