import { defineStore } from 'pinia'
import { useNewSettingsStore } from '~/store/newSettingsStore'
import { getI18nLanguageId } from '~/utils/languages'
import { AUTH_COOKIE_NAME } from '~/constants/cookies'

interface RegisterParams {
  email: string
  errorMessage: string
  firstName: string
  hCaptchaToken: string
  isSubscribedToNewsletter: boolean
  lastName: string
  password: string
}

interface User {
  email: string
  firstName: string
  gender: string
  languageId?: number
  lastName: string
  phoneCode: string
  phoneNumber: string
  title: string
}

interface Address {
  addressLine1: string
  addressLine2: string
  city: string
  countryId: number
  county?: string
  firstName: string
  isDefaultBilling: false
  isDefaultDelivery: false
  lastName: string
  phoneCode: string
  phoneNumber: string
  postCode: string
  title: string
}

const useUserStore = defineStore('user', {
  state: () => ({
    addresses: [] as Address[],
    askForGender: null as boolean | null,
    customerId: '',
    errorMessage: '',
    masterCustomerIdentifier: '',
    orders: [],
    salutations: '',
    source: null,
    user: {
      email: '',
      firstName: '',
      gender: '',
      languageId: null,
      lastName: '',
      phoneCode: '',
      phoneNumber: '',
      title: '',
    } as User,
    loginDetails: {
      password: '',
      newPassword: '',
    },
  }),
  persist: {
    storage: piniaPluginPersistedstate.sessionStorage(),
  },
  getters: {
    userFullName: (state) => (state.user?.firstName ? `${state.user.firstName} ${state.user.lastName}` : ''),
    isLoggedIn: (state) => {
      const authCookie = useCookie(AUTH_COOKIE_NAME)
      const hasAuthCookie = !!authCookie?._rawValue

      return (!!state.user?.email && !!state?.masterCustomerIdentifier && !!state?.customerId) || hasAuthCookie
    },
    countryId: () => {
      const settings = useNewSettingsStore()

      return settings.currentCountry?.Id || 1
    },
  },
  actions: {
    showAlert(variant: 'success' | 'danger', message) {
      const { $alert } = useNuxtApp()

      $alert({
        variant,
        message,
      })
    },
    setUserPhoneCode(newPhoneCodeValue: string) {
      this.user.phoneCode = newPhoneCodeValue
    },
    setUserLanguageId(newLanguageId: number) {
      this.user.languageId = newLanguageId
    },
    resetUserData() {
      this.user = {
        email: '',
        firstName: '',
        gender: '',
        languageId: null,
        lastName: '',
        phoneCode: '',
        phoneNumber: '',
        title: '',
      }
    },
    resetUserLoginDetails() {
      this.loginDetails = {
        password: '',
        newPassword: '',
      }
    },
    resetUser() {
      this.resetUserData()
      this.resetUserLoginDetails()

      this.addresses = []
      this.askForGender = null
      this.customerId = ''
      this.errorMessage = ''
      this.masterCustomerIdentifier = ''
      this.orders = []
      this.salutations = ''
      this.source = null
    },
    setOrders(newValue) {
      this.orders = newValue
    },
    handleError(errorCode?: string) {
      const { translate } = useTranslations()
      this.errorMessage = translate('something-went-wrong', 'Something went wrong, please try again later')

      if (errorCode) {
        this.errorMessage = `(${errorCode}) ${message}`
      }

      this.showAlert('danger', this.errorMessage)
    },
    // old meEndpoint
    async getUserMe() {
      try {
        const response = await useFetch('/api/auth/me', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', accept: '*/*' },
        })
        const { masterCustomerIdentifier, customerId, source } = response.data.value

        this.masterCustomerIdentifier = masterCustomerIdentifier
        this.customerId = customerId
        this.source = source

        return true
      } catch {
        return false
      }
    },
    // old getUser
    async loadUserData() {
      try {
        const response = await useFetch('/api/account/user-data', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', accept: '*/*' },
        })

        const data = response?.data?._value

        if (data?.email && data?.masterCustomerIdentifier) {
          this.user = { ...data }
          return true
        }

        return false
      } catch {
        return false
      }
    },
    async register(registerParams: RegisterParams) {
      const { firstName, lastName, email, password, isSubscribedToNewsletter, hCaptchaToken } = registerParams

      try {
        await useFetch(`/api/auth/register`, {
          method: 'POST',
          body: {
            firstName,
            lastName,
            email,
            password,
            isSubscribedToNewsletter,
            'h-captcha-response': hCaptchaToken,
            languageId: getI18nLanguageId(),
          },
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        })

        return true
      } catch {
        return this.handleError()
      }
    },
    // old updateAccount
    async updateUserMe() {
      return useFetch('/api/auth/update-me', {
        method: 'PUT',
        body: {
          firstName: this.user.firstName,
          gender: this.user.gender,
          languageId: this.user.languageId,
          lastName: this.user.lastName,
          phoneCode: this.user.phoneCode,
          phoneNumber: this.user.phoneNumber,
          title: this.user.title,
        },
      })
        .then((response) => response?.status?._value !== 'error')
        .catch(() => false)
    },
    async getAddresses() {
      return $fetch('/api/auth/address-info', {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (response?.addresses?.length > 0) {
            this.addresses = response.addresses
            return true
          }

          this.addresses = []
          return false
        })
        .catch((e) => {
          console.error('Error to load addresses', e)
          this.addresses = []
          return false
        })
    },
    async getOrders() {
      try {
        const response = await useFetch('/api/account/orders', {
          method: 'POST',
          body: { masterCustomer: this.masterCustomerIdentifier, countryId: this.countryId },
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (response?.data?._value?.length > 0) {
          this.orders = response.data._value
          return true
        }
        return false
      } catch (error) {
        return false
      }
    },
    async deleteAddress(addressId: string) {
      try {
        await useFetch(`/api/auth/address-remove/${addressId}`, {
          method: 'DELETE',
        })

        return true
      } catch {
        return false
      }
    },
    async editAddress(newAddress) {
      return useFetch(`/api/auth/address-edit/${newAddress.id}`, {
        method: 'PUT',
        body: newAddress,
      })
        .then((response) => response?.status?._value !== 'error')
        .catch(() => {
          return false
        })
    },
    async addAddress(newAddressData) {
      return useFetch('/api/auth/address-add', {
        method: 'POST',
        body: newAddressData,
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => response?.status?._value !== 'error')
        .catch(() => {
          return false
        })
    },
    async updatePassword({ password, newPassword }) {
      return await useFetch('/api/auth/update-password', {
        method: 'PUT',
        body: {
          oldPassword: password,
          password: newPassword,
        },
      })
        .then((response) => response.status._value !== 'error')
        .catch(() => {
          return false
        })
    },
  },
})

export default useUserStore
