import { fetchFromNewApi } from '~/helpers/api.ts'

export const fetchSettingsExpressSettings = async () => {
  try {
    return await fetchFromNewApi('/api/v1/shop/express/settings')
  } catch (error) {
    console.error(error)
    return null
  }
}

export const fetchSettingsShopSettings = async () => {
  try {
    return await fetchFromNewApi('/api/v1/shop/settings')
  } catch (error) {
    console.error(error)
    return null
  }
}
