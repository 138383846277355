import { useNewSettingsStore } from '~/store/newSettingsStore'

const getCurrency = () => {
  const settings = useNewSettingsStore()
  return settings.currentCurrency || {}
}

const getCurrencySymbol = () => {
  const settings = useNewSettingsStore()
  return settings.getCurrentCurrencySymbol()
}

export function isGBP() {
  return getCurrency().Id === 1
}

export function isIDR() {
  return getCurrency().Id === 26
}

const digitsRE = /(\d{3})(?=\d)/g
let ts = ',' // thousand separator

export function currency(value, currency = getCurrencySymbol(), decimals = 2) {
  value = parseFloat(value)
  if (!isFinite(value) || (value === undefined && value !== 0)) return ''

  const stringified = Math.abs(value).toFixed(decimals)
  const _int = decimals ? stringified.slice(0, -1 - decimals) : stringified
  const i = _int.length % 3
  const head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? ts : '') : ''
  const _float = decimals ? stringified.slice(-1 - decimals) : ''
  const sign = value < 0 ? '-' : ''

  return sign + currency + head + _int.slice(i).replace(digitsRE, '$1' + ts) + _float
}
