import { useRouteStore } from '@/store/routesStore'

export function useRoutesHelper() {
  const routesStore = useRouteStore()
  const route = useRoute()

  const isShopRoute = () => routesStore.getUrlLanguageByPath(route.path)

  const getRouteByLanguage = (language: string) => {
    const localePath = useLocalePath()

    if (isShopRoute()) {
      return routesStore.getUrlLanguageByLocale(language)?.path || route.path // translate shop language to language path
    } else {
      const pathFromBrowserUrl = (window?.location.pathname || route.path) + (window?.location.search || route.query) // handle hydration case
      return localePath(pathFromBrowserUrl, language) // translate language to language path
    }
  }

  return {
    isShopRoute,
    getRouteByLanguage,
  }
}
