export const useTranslations = () => {
  const { t, messages } = useI18n() // Access i18n instance

  // **Static Translate Function**
  const translate = (key, params = {}) => {
    if (!key) return '' // Handle empty or invalid keys
    return t(key, params) // Direct translation
  }

  // **Check if Key Exists**
  const handleDynamicKey = (key) => {
    // at the moment this check is enough can be extended later
    if (!key || typeof key !== 'string') {
      return '' // Return empty string if key is invalid
    }
    return key
  }

  // handle dynamic key, and update translation once i18n.messages is updated
  const computedTranslation = (key, ...options) => {
    return computed(() => {
      return t(handleDynamicKey(key), ...options) // Return translation
    })
  }

  return {
    translate, // For static usage
    computedTranslation, // For dynamic usage
    handleDynamicKey,
  }
}
