const LookingForKeys = ['earrings', 'cocktail-rings', 'necklaces', 'bracelets', 'other'] as const
type LookingForOptions = (typeof LookingForKeys)[number]

export const LookingForLabels: { [key in LookingForOptions]: string } = {
  earrings: 'Earrings',
  'cocktail-rings': 'Cocktail Rings',
  necklaces: 'Necklaces',
  bracelets: 'Bracelets',
  other: 'Other',
}

type LookingForOption = {
  id: LookingForOptions
  label: string
}
const LookingForOptions: LookingForOption[] = LookingForKeys.map((item) => ({
  id: item,
  label: LookingForLabels[item],
}))

export default LookingForOptions
